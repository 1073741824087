import MergeMapContainer from "../components/MergeMapContainer";
import { StaticPageContainer } from "../components/StaticPageContainer";
import { isValidUrl } from "../models/Helpers";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { useState, useCallback, useRef, FormEvent } from "react";
import styled from "styled-components";
import HardcodedInitialView from "../components/initial_view/HardcodedInitialView";
import LoadingStates from "../components/loading/LoadingStates";
import {
  CategoryStatus,
  CheckLLMStatus,
  FavIconResult,
  KickOffLLM,
  LLMStatus,
  ValidationErrors,
} from "../models/Entities";
import { useEffect } from "react";
import FinalStageComponent from "../components/finished/FinalStageComponent";
import { fetchWithoutAuth } from "../api-client/APIClient";
import { fetchFaviconAndDomain } from "../models/Helpers";
import ErrorComponent from "../components/finished/ErrorComponent";
import { useContext } from "react";
import Context from "../context/Context";
import InputBar from "../navigation/InputBar";
import InitialViewFade from "../components/initial_view/Fade";

interface Props {
  setSeeMoreView: React.Dispatch<React.SetStateAction<boolean>>;
  inView: boolean;
}

const Tag = styled.a`
  color: var(--blue40);
  text-decoration: none;

  &:hover {
    color: var(--blue60);
    cursor: pointer;
  }
`;

const MergeMap = ({ setSeeMoreView, inView }: Props) => {
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    States   ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  // const [submittedCategory, setSubmittedCategory] = useState<string>("");

  const [checkLLMStatus, setCheckLLMStatus] = useState<CheckLLMStatus | null>(
    null
  );

  const [kickOffLLMStatus, setKickOffLLMStatus] = useState<KickOffLLM | null>(
    null
  );

  const [urlInfo, setURLInfo] = useState<FavIconResult | null>(null);

  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    invalidURL: false,
    nonSelectedCategory: false,
  });

  const {
    hasEnteredEmail,
    emailEntered,
    expandedBackground,
    setExpandedBackground,
    kickOffRun,
    setKickOffRun,
    currentURLForRun,
    setCurrentURLForRun,
    currentCategory,
    setCurrentCategory,
    setCurrentExecutionID,
  } = useContext(Context);

  const [errorState, setErrorState] = useState<boolean>(false);

  const [isLoading, setLoadingState] = useState<boolean>(false); // principle loading state

  const [finalState, setFinalState] = useState<boolean>(false); // final screen after loading

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Callback functions to handle rendering   ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  const handleFinishedTransitions = () => {
    // once our core transitions are finished, bring them to the final page where we should our mappings
    setFinalState(true);
  };

  const handleStopLoadingState = () => {
    setLoadingState(false);
  };

  const handleNeedsReview = () => {
    setFinalState(false);
  };

  useEffect(() => {
    if (!checkLLMStatus) {
      setSeeMoreView(false);
    }
    if (
      checkLLMStatus?.status ===
        LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED ||
      checkLLMStatus?.status ===
        LLMStatus.LLM_MAPPING_EXECUTION_STATUS_FAILED ||
      checkLLMStatus?.status ===
        LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW
    ) {
      setLoadingState(false);
    }
  }, [checkLLMStatus, selectedCategory, inView]);

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    AUTH METHODS     ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  const kickOffLLM = (
    url_input: string,
    category_input: string,
    signup_email?: string
  ) => {
    fetchWithoutAuth({
      path: "/ai/kick-off-llm-run",
      method: "POST",
      body: {
        api_docs_url: url_input,
        category: category_input,
        signup_email: signup_email,
      },
      onResponse: (data: KickOffLLM) => {
        checkStatus(data.execution_id);
        setCurrentExecutionID(data.execution_id);
        setKickOffLLMStatus(data);
        setFinalState(false);
        setURLInfo(fetchFaviconAndDomain(url_input));
      },
      onError: () => {
        setLoadingState(false);
        setErrorState(true);
        setValidationErrors({
          ...validationErrors,
          invalidURL: true,
        });
      },
    });
  };

  const checkStatus = (executionID: string, isQueryParam?: boolean) => {
    fetchWithoutAuth({
      path: `/ai/check-llm-status/${executionID}`,
      method: "GET",
      onResponse: (data: CheckLLMStatus) => {
        setCheckLLMStatus(data);
        if (
          data.status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED &&
          data.status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW &&
          data.status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_FAILED
        ) {
          setTimeout(() => checkStatus(executionID), 1000);
        }
        if (
          data.status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED &&
          isQueryParam
        ) {
          getCategories(executionID);
          setFinalState(true);
          if (hasEnteredEmail) {
            setExpandedBackground(true);
          }
        }
      },
      onError: () => {
        setLoadingState(false);
      },
    });
  };

  const getCategories = (execution_id: string) => {
    fetchWithoutAuth({
      path: `/ai/category-models/${execution_id}`,
      method: "GET",
      onResponse: (data: CategoryStatus) => {
        const updatedStatus: KickOffLLM = {
          "apply!": "apply",
          execution_id: execution_id,
          category_models: data.category_models,
        };
        setKickOffLLMStatus(updatedStatus);
        setCurrentCategory(data.category);
        setSelectedCategory(data.category);
        setCurrentExecutionID(execution_id);
        setLoadingState(false);
        setErrorState(false);
        setCurrentURLForRun(data.api_docs_url);
        setURLInfo(fetchFaviconAndDomain(data.api_docs_url));
      },
      onError: () => {},
    });
  };

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Form Handling     ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault();

      if (!isValidUrl(currentURLForRun) || !selectedCategory) {
        setErrorState(true);

        const newValidationErrors: ValidationErrors = {
          invalidURL: false,
          nonSelectedCategory: false,
        };

        if (!isValidUrl(currentURLForRun)) {
          newValidationErrors.invalidURL = true;
        }

        if (!selectedCategory) {
          newValidationErrors.nonSelectedCategory = true;
        }

        setValidationErrors(newValidationErrors);

        return;
      }

      if (hasEnteredEmail && !expandedBackground) {
        setExpandedBackground(true);
        setSeeMoreView(false);
      }
      setErrorState(false);
      setValidationErrors({
        invalidURL: false,
        nonSelectedCategory: false,
      });
      setCheckLLMStatus(null);
      kickOffLLM(
        currentURLForRun,
        selectedCategory,
        hasEnteredEmail ? emailEntered : undefined
      );
      setCurrentCategory(selectedCategory);
      setCurrentURLForRun(currentURLForRun);
      setLoadingState(true);
    },
    [
      selectedCategory,
      emailEntered,
      hasEnteredEmail,
      expandedBackground,
      currentURLForRun,
    ]
  );

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Context Kick-offs     ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  useEffect(() => {
    if (kickOffRun) {
      setSelectedCategory(currentCategory);
      kickOffLLM(
        currentURLForRun,
        currentCategory,
        hasEnteredEmail ? emailEntered : undefined
      );
      if (hasEnteredEmail && !expandedBackground) {
        setExpandedBackground(true);
        setSeeMoreView(false);
      }
      setErrorState(false);
      setCheckLLMStatus(null);
      setValidationErrors({
        invalidURL: false,
        nonSelectedCategory: false,
      });
      setLoadingState(true);
      setKickOffRun(false);
    }
  }, [kickOffRun]);

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Query param kickoff     ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const executionId = params.get("execution_id");
    if (executionId) {
      checkStatus(executionId, true);
    } else {
    }
  }, []);

  return (
    <StaticPageContainer>
      <MergeMapContainer>
        <h2 className="mb-2">Try Merge Blueprint</h2>
        <div className="mb-2 text-md">
          See how third-party fields are normalized to Merge's Common Models
          using AI.
        </div>
        <div className="mb-6 text-md">
          Select a category and enter a platform's <b>API Reference URL</b>,
          making sure that it contains <b>all models and endpoints</b>. See
          examples of good and bad URLs in{" "}
          <Tag href="https://merge.dev/blueprint#faq">FAQs</Tag>.
        </div>
        <InputBar
          disabled={isLoading}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          className="mb-9"
          handleSubmit={handleSubmit}
        />
        {!checkLLMStatus ? (
          <div>
            {errorState ? (
              <ErrorComponent
                stopLoadingState={handleStopLoadingState}
                errors={validationErrors}
              />
            ) : (
              <div>
                <HardcodedInitialView
                  noLongerRendered={isLoading || errorState || finalState}
                />
                <InitialViewFade
                  noLongerRendered={isLoading || errorState || finalState}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="h-100">
            {errorState && !isLoading && (
              <ErrorComponent
                stopLoadingState={handleStopLoadingState}
                status={checkLLMStatus}
                errors={validationErrors}
              />
            )}
            {checkLLMStatus.status !==
            LLMStatus.LLM_MAPPING_EXECUTION_STATUS_FAILED ? (
              !finalState ? (
                <LoadingStates
                  checkLLMStatus={checkLLMStatus}
                  finishedLoadingTransitions={handleFinishedTransitions}
                />
              ) : (
                kickOffLLMStatus && (
                  <FinalStageComponent
                    urlInfo={urlInfo}
                    categoryModels={kickOffLLMStatus.category_models}
                    checkLLMStatus={checkLLMStatus}
                    needsReview={handleNeedsReview}
                  />
                )
              )
            ) : (
              <ErrorComponent
                stopLoadingState={handleStopLoadingState}
                status={checkLLMStatus}
                errors={validationErrors}
              />
            )}
          </div>
        )}
      </MergeMapContainer>
    </StaticPageContainer>
  );
};

export default MergeMap;
