import { useInView } from "react-intersection-observer";
import { useContext, useEffect, useState } from "react";
import Context from "../context/Context";
import styled from "styled-components";
import MergeMap from "./MergeMap";
import SeeMoreView from "./SeeMoreView";

interface ContainerProps {
  expand: boolean;
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: transparent;
`;

const Container = styled.div<ContainerProps>`
  width: 100%;
  max-width: ${(props) => (props.expand ? "100%" : "1196px")};
  min-height: ${(props) => (props.expand ? "calc(100vh)" : "731px")};
  max-height: ${(props) => (props.expand ? "none" : "731px")};
  @media (max-width: 602px) {
    ${(props) => (props.expand ? "max-height: none;" : "max-height: 931px;")}
  }
  transition: max-width 1s ease-in-out, min-height 1s ease-in-out,
    max-height 1s ease-in-out, box-shadow 1s ease-in-out,
    border-radius 1s ease-in-out, padding-top 1s ease-in-out;
  background-color: #f5f7fa;
  overflow: hidden;
  ${(props) => (props.expand ? "padding-top: 60px;" : "")}
  ${(props) => (props.expand ? "" : "border-radius: 12px;")}
  position: relative;
  ${(props) =>
    props.expand
      ? ""
      : "box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1), 0 0 0 0.5px rgba(220, 226, 234, 0.2), 0 3px 12px -3px rgba(0, 0, 0, 0.12);"}
`;

const BackgroundContainer = () => {
  const { expandedBackground, setExpandedBackground, hasEnteredEmail } =
    useContext(Context);
  const [seeMoreView, setSeeMoreView] = useState<boolean>(false);

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const handleSeeMoreState = () => {
    setSeeMoreView(false);
  };

  useEffect(() => {
    if (!inView && hasEnteredEmail) {
      setExpandedBackground(false);
      setSeeMoreView(true);
    }
  }, [inView, expandedBackground, hasEnteredEmail]);

  /*
   * Note to future implementors - do not remove the "id: merge-blueprint-main" - the dropdown inside the grid container requires this to be injected into the DOM. If you need to remove or alter this please put "merge-blueprint-main" onto another div
   */

  return (
    <PageContainer className="justify-content-center">
      <Container
        id="merge-blueprint-main"
        ref={ref}
        expand={expandedBackground}
      >
        <MergeMap setSeeMoreView={setSeeMoreView} inView={inView} />
        {seeMoreView && <SeeMoreView handleSeeMoreView={handleSeeMoreState} />}
      </Container>
    </PageContainer>
  );
};

export default BackgroundContainer;
