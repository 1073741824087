import { FormEvent, useContext, useEffect, useCallback } from "react";
import { Form, Dropdown, Accordion, Card } from "react-bootstrap";
import Context from "../context/Context";
import styled, { css } from "styled-components";
import { MergeFormButton } from "@merge-api/merge-javascript-shared";
import {
  defaultCategoryUrls,
  displayNameForAPICategory,
  prefillCategorySelectURL,
} from "../models/Helpers";
import { APICategory } from "@merge-api/merge-javascript-shared";
import { ChevronDown } from "lucide-react";
import classNames from "classnames";
import { useState } from "react";
import MergeModal from "../components/merge-components/MergeModal";
import { ReactComponent as SampleAPIDoc } from "../assets/images/SampleAPIDocumentation.svg";

interface Props {
  setSelectedCategory: React.Dispatch<React.SetStateAction<string | null>>;
  selectedCategory: string | null;
  className?: string;
  disabled: boolean;
  handleSubmit: (event: React.FormEvent) => void;
}

interface CustomToggleProps {
  onClick: () => void;
}

const FormButtonContainer = styled.div`
  min-width: 121px;
  width: 100%;
  flex: 1;
`;

const CustomDropdownToggle = styled(Dropdown.Toggle)`
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  .custom-dropdown-toggle::after {
    display: none;
  }
  min-width: 200px;
`;

const MobileResponsiveDropdown = styled(Dropdown)`
  display: flex !important;
  width: 100%;
  min-width: 200px;
`;

const MobileResponsiveForm = styled.div`
  flex: 10;
  display: flex;
  width: 100%;
  min-width: 200px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(200px, 1fr) max-content;
  grid-auto-rows: auto;
  row-gap: 12px;
  column-gap: 16px;

  @media (max-width: 602px) {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
  }
`;

const MaxWidthMergeModal = styled(MergeModal)`
  max-width: 524px !important;
`;

const BlackLink = styled.a`
  color: #000000;
  text-decoration: none;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
`;

const CardBody = styled.div`
  display: flex;
  padding: 0 12px 12px 12px;
`;

const RotatingChevron = styled(ChevronDown)<{ dropDownOpen: boolean }>`
  transition: transform 0.1s ease-in-out;
  will-change: transform;
  color: var(--gray50);
  cursor: pointer;
  ${(props) =>
    props.dropDownOpen &&
    css`
      transform: rotate(-180deg);
    `};
`;

const ResponsiveImage = styled(SampleAPIDoc)`
  width: 100%;
  height: auto;
`;

const InputBar = ({
  setSelectedCategory,
  selectedCategory,
  className,
  disabled,
  handleSubmit,
}: Props) => {
  const {
    currentURLForRun,
    setCurrentURLForRun,
    hasSeenURLReqModal,
    setHasSeenURLReqModal,
  } = useContext(Context);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleModalClose = () => {
    setShowModal(false);
    setIsOpen(false);
    if (isChecked) {
      setHasSeenURLReqModal(true);
    }
  };

  const CustomToggle: React.FC<CustomToggleProps> = ({ onClick }) => {
    return (
      <CardHeader onClick={onClick}>
        <div className="d-flex align-items-center">
          <div className="mr-1.5 semibold black">
            See an example of a valid API reference page
          </div>
        </div>{" "}
        <RotatingChevron size={12} dropDownOpen={isOpen} />
      </CardHeader>
    );
  };

  useEffect(() => {
    if (
      selectedCategory &&
      (currentURLForRun === "" ||
        defaultCategoryUrls.includes(currentURLForRun))
    ) {
      setCurrentURLForRun(prefillCategorySelectURL(selectedCategory));
    }
  }, [selectedCategory]);

  return (
    <div className={`d-flex flex-column w-100 ${className}`}>
      <Container>
        <MobileResponsiveDropdown className="dropdown-toggle-no-chevron">
          <CustomDropdownToggle
            disabled={disabled}
            variant="white"
            className={classNames(
              "shadow-md d-flex justify-content-between align-items-center custom-dropdown-toggle dropdown-toggle-no-chevron"
            )}
          >
            {selectedCategory ? (
              displayNameForAPICategory(selectedCategory)
            ) : (
              <div className="text-gray-40">Select category</div>
            )}
            <ChevronDown size={12} />
          </CustomDropdownToggle>
          <Dropdown.Menu className="dropdown-menu-search">
            {(Object.values(APICategory) as string[]).map(
              (category: string) => {
                return (
                  <Dropdown.Item
                    key={category}
                    onClick={() => {
                      setSelectedCategory(category);
                    }}
                  >
                    {displayNameForAPICategory(category)}
                  </Dropdown.Item>
                );
              }
            )}
          </Dropdown.Menu>
        </MobileResponsiveDropdown>
        <MobileResponsiveForm>
          <Form.Control
            className="text-truncate shadow-md"
            disabled={disabled}
            as="input"
            name="urlInput"
            type="text"
            placeholder="Enter a platform’s API Reference URL"
            value={currentURLForRun}
            onChange={(e) => setCurrentURLForRun(e.target.value)}
            style={{ height: "40px", width: "100%" }}
          />
        </MobileResponsiveForm>
        <FormButtonContainer>
          <MergeFormButton
            stretch
            size="medium"
            color="black"
            isLoading={disabled}
            onClick={(event: FormEvent<Element>) => {
              if (hasSeenURLReqModal) {
                handleSubmit(event);
              } else {
                setShowModal(true);
              }
            }}
            type="submit"
          >
            {disabled ? "Generating" : "Generate"}
          </MergeFormButton>
        </FormButtonContainer>
      </Container>
      <MaxWidthMergeModal
        show={showModal}
        size="lg"
        onHide={() => setShowModal(false)}
        fitContent={true}
        title="URL Requirements"
      >
        <div className="mb-3">
          In order for Blueprint to provide you with the most accurate results,
          your API reference URL must include:
        </div>
        <ul className="mb-3">
          <li>all models and endpoints, or</li>
          <li>links to all models and endpoints</li>
        </ul>
        <div className="semibold mb-5">
          Please double check to make sure your URL meets the above criteria.
        </div>
        <Accordion className="w-100 mb-0" activeKey={isOpen ? "0" : undefined}>
          <Card className="mb-0">
            <CustomToggle onClick={handleClick} />
            <Accordion.Collapse eventKey="0">
              <CardBody>
                <ResponsiveImage className="rounded-md" />
              </CardBody>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <div className="text-gray-50 text-sm mt-6">
          By continuing, you agree to our{" "}
          <BlackLink href="https://merge.dev/legal/terms" target="_blank">
            Terms of Use
          </BlackLink>
          .
        </div>
        <MergeFormButton
          className="mt-3 mb-5"
          stretch
          size="medium"
          color="black"
          onClick={(event: FormEvent<Element>) => {
            handleModalClose();
            handleSubmit(event);
          }}
        >
          Generate
        </MergeFormButton>
        <Form>
          <Form.Check
            className="d-flex align-items-center"
            onChange={() => setIsChecked(true)}
          >
            <Form.Check.Input
              name="agreed_to_not_see_again"
              id="agreed_to_not_see_again"
              type="checkbox"
              className="form-check-input"
              onChange={() => setIsChecked(!isChecked)}
            />
            <Form.Check.Label
              className="small"
              htmlFor="agreed_to_not_see_again"
            >
              Don't show this message again
            </Form.Check.Label>
          </Form.Check>
        </Form>
      </MaxWidthMergeModal>
    </div>
  );
};

export default InputBar;
