import { useState, useCallback } from "react";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { FieldSchema } from "../../models/Entities";
import styled, { css } from "styled-components";
import { ChevronDown } from "lucide-react";
import { MergeBadge } from "@merge-api/merge-javascript-shared";
import { capitalize } from "lodash";

interface Props {
  filteredFieldSchema: FieldSchema[];
}

interface CustomToggleProps {
  onClick: () => void;
}

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  cursor: pointer;
`;

const CardBody = styled.div`
  display: flex;
  padding: 0 12px 12px 12px;
`;

const RotatingChevron = styled(ChevronDown)<{ dropDownOpen: boolean }>`
  transition: transform 0.1s ease-in-out;
  will-change: transform;
  color: var(--gray50);
  ${(props) =>
    props.dropDownOpen &&
    css`
      transform: rotate(-180deg);
    `};
`;

const FilteredFields = ({ filteredFieldSchema }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const CustomToggle: React.FC<CustomToggleProps> = ({ onClick }) => {
    return (
      <CardHeader onClick={onClick}>
        <div className="d-flex align-items-center">
          <div className="mr-1.5 semibold black">Unmapped Fields</div>
          <MergeBadge color="gray" size="small">
            {filteredFieldSchema.length ?? 0}
          </MergeBadge>
        </div>{" "}
        <RotatingChevron size={12} dropDownOpen={isOpen} />
      </CardHeader>
    );
  };

  return (
    <Accordion className="w-100 mb-0" activeKey={isOpen ? "0" : undefined}>
      <Card className="shadow-md mb-0">
        <CustomToggle onClick={handleClick} />
        <Accordion.Collapse eventKey="0">
          <CardBody>
            <div className="d-flex flex-column px-3 py-2.5 align-items-start border rounded-md w-100">
              {filteredFieldSchema.map((fieldSchema, index) => (
                <div
                  key={index}
                  className={`d-flex align-items-center ${
                    index !== filteredFieldSchema.length - 1 ? "mb-1" : ""
                  }`}
                >
                  <div className="text-md-mono mr-3 text-truncate">
                    {fieldSchema?.field}
                  </div>
                  <div className="text-gray-50 text-sm semibold">
                    {capitalize(fieldSchema?.type)}
                  </div>
                </div>
              ))}
            </div>
          </CardBody>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default FilteredFields;
