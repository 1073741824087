import React, { useContext } from "react";
import styled from "styled-components";
import Context from "../context/Context";

interface MergeMapContainerProps {
  children: React.ReactNode;
}

interface MaxHeightProps {
  limitHeight: boolean;
}

const Card = styled.div<MaxHeightProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background: #f5f7fa;
  border-radius: 12px;
  position: relative;
  overflow: visible;
  ${(props) => (props.limitHeight ? "height: 100%;" : "max-height: 731px;")}
  @media (max-width: 602px) {
    ${(props) => (props.limitHeight ? "height: 100%;" : "max-height: 931px;")}
  }
`;

const MergeMapContainer: React.FC<MergeMapContainerProps> = ({ children }) => {
  const { hasEnteredEmail, expandedBackground } = useContext(Context);

  return (
    <Card limitHeight={hasEnteredEmail || expandedBackground}>{children}</Card>
  );
};

export default MergeMapContainer;
