import { APICategory } from "@merge-api/merge-javascript-shared";
import { useContext, useState, useRef, ReactNode, Children } from "react";
import styled from "styled-components";
import Context from "../../context/Context";
import { getDomainName } from "../../models/Helpers";

interface Props {
  category: CategoryKey;
  className?: string;
}

type CategoryKey = keyof typeof APICategory;

type Integration = {
  domain_name: string;
  image: string;
  url: string;
};

type Integrations = {
  [category in CategoryKey]: Integration[];
};

const integrations: Integrations = {
  accounting: [
    {
      domain_name: "intuit",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/Quickbooks_white.svg",
      url: "https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account",
    },
    {
      domain_name: "xero",
      image: "https://merge-api-production.s3.amazonaws.com/media/xero-3.svg",
      url: "https://developer.xero.com/documentation/api/accounting/overview",
    },
    {
      domain_name: "netsuite",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/netsuite_white.svg",
      url: "https://www.netsuite.com/help/helpcenter/en_US/srbrowser/Browser2016_1/schema/record/account.html",
    },
    {
      domain_name: "freshbooks",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/freshbooks-logo-white_Zl6iDyL.svg",
      url: "https://www.freshbooks.com/api/start",
    },
  ],
  ats: [
    {
      domain_name: "greenhouse",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/greenhouse.svg",
      url: "https://developers.greenhouse.io/harvest.html#introduction",
    },
    {
      domain_name: "workday",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/workday_uZ1qPH6.svg",
      url: "https://community.workday.com/sites/default/files/file-hosting/productionapi/index.html",
    },
    {
      domain_name: "ashbyhq",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/ashby_A0gQsIn.svg",
      url: "https://developers.ashbyhq.com/reference/introduction",
    },
    {
      domain_name: "sap",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/successfactors.svg",
      url: "https://api.sap.com/package/SuccessFactorsEmployeeCentral/all",
    },
  ],
  hris: [
    {
      domain_name: "paylocity",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/paylocity-4.svg",
      url: "https://prod.cdn.paylocity.com/developer/index.html#tag/Company-Codes",
    },
    {
      domain_name: "bamboohr",
      image: "https://merge-api-production.s3.amazonaws.com/media/bamboohr.svg",
      url: "https://documentation.bamboohr.com/reference",
    },
    {
      domain_name: "hibob",
      image: "https://merge-api-production.s3.amazonaws.com/media/hibob.svg",
      url: "https://apidocs.hibob.com/reference",
    },
    {
      domain_name: "ultipro",
      image: "https://merge-api-production.s3.amazonaws.com/media/ukg.svg",
      url: "https://connect.ultipro.com/documentation#/api",
    },
  ],
  ticketing: [
    {
      domain_name: "clickup",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/clickup_white.svg",
      url: "https://clickup.com/api/",
    },
    {
      domain_name: "atlassian",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/jira_white_1dSQVsH.svg",
      url: "https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issues/#api-group-issues",
    },
    {
      domain_name: "asana",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/asana_white_ywcVYgJ.svg",
      url: "https://developers.asana.com/reference/rest-api-reference",
    },
    {
      domain_name: "gitlab",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/GitLab_white.svg",
      url: "https://docs.gitlab.com/ee/api/api_resources.html",
    },
  ],
  mktg: [
    {
      domain_name: "activecampaign",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/ActiveCampaign_white.svg",
      url: "https://developers.activecampaign.com/reference/overview",
    },
    {
      domain_name: "customer",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/customer_io_White_PtYBB3C.svg",
      url: "https://customer.io/docs/api/",
    },
    {
      domain_name: "getresponse",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/getresponse_White_JuZOi4p.svg",
      url: "https://api.getresponse.com/v3",
    },
    {
      domain_name: "hubspot",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/Hubspot_white_f3go2Lq.svg",
      url: "https://developers.hubspot.com/docs/api/marketing/marketing-events",
    },
  ],
  crm: [
    {
      domain_name: "accelo",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/accelo_white.svg",
      url: "https://api.accelo.com/docs/?_ga=2.93037978.640763431.1674847441-1722422118.1674847441#introduction",
    },
    {
      domain_name: "affinity",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/affinity_white.svg",
      url: "https://api-docs.affinity.co/#introduction",
    },
    {
      domain_name: "capsulecrm",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/Capsule_1.svg",
      url: "https://developer.capsulecrm.com/v2/overview/getting-started",
    },
    {
      domain_name: "close",
      image: "https://merge-api-production.s3.amazonaws.com/media/Close_3.svg",
      url: "https://developer.close.com/",
    },
  ],
  filestorage: [
    {
      domain_name: "microsoft",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/sharepoint_white.svg",
      url: "https://learn.microsoft.com/en-us/graph/api/resources/sharepoint?view=graph-rest-1.0",
    },
    {
      domain_name: "dropbox",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/Dropbox_white.svg",
      url: "https://www.dropbox.com/developers/documentation/http/documentation",
    },
    {
      domain_name: "google",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/Google_Drive_white.svg",
      url: "https://developers.google.com/drive/api/guides/about-sdk",
    },
    {
      domain_name: "box",
      image:
        "https://merge-api-production.s3.amazonaws.com/media/OneDrive_white.svg",
      url: "https://developer.box.com/reference/",
    },
  ],
};

interface FadeOut {
  fadeOut: boolean;
}

const FullContainer = styled.div<FadeOut>`
  opacity: ${(props) => (props.fadeOut ? "0" : "1")};
  transition: 1s ease-out;
`;

interface IntegrationContainerProps {
  children: ReactNode;
}

const IntegrationContainer = styled.div<IntegrationContainerProps>`
  display: grid;
  grid-template-columns: repeat(
    ${(props) => Children.count(props.children)},
    minmax(200px, 1fr)
  );
  grid-auto-rows: auto;
  grid-gap: 16px;
  width: 100%;
  @media (max-width: 940px) {
    grid-template-rows: repeat(
      ${(props) => Children.count(props.children)},
      1fr
    );
    grid-template-columns: minmax(200px, 1fr);
  }
`;

const IntegrationCard = styled.div`
  background-color: var(--slate80);
  padding: 21px 12px;
  display: flex;
  min-width: 200px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;

const MaxImageHeight = styled.img`
  max-height: 25px;
`;

const TryAnotherIntegration = ({ category, className }: Props) => {
  const {
    setKickOffRun,
    setCurrentURLForRun,
    setCurrentCategory,
    currentURLForRun,
  } = useContext(Context);

  const [fadeOut, setFadeOut] = useState<boolean>(false);

  const inputtedURLRunRef = useRef(currentURLForRun);

  const handleNewRun = (url: string, category: string) => {
    setCurrentURLForRun(url);
    setCurrentCategory(category);
    setKickOffRun(true);
    setFadeOut(true);
  };

  return (
    <FullContainer
      fadeOut={fadeOut}
      className={`d-flex flex-column ${className}`}
    >
      <h5 className="mb-4">Try another similar platform...</h5>
      <IntegrationContainer>
        {integrations[category]
          .filter(
            (integration) =>
              integration.domain_name !==
              getDomainName(inputtedURLRunRef.current)
          )
          .map((integration) => (
            <IntegrationCard
              key={integration.domain_name}
              onClick={() => handleNewRun(integration.url, category as string)}
            >
              <MaxImageHeight
                src={integration.image}
                alt={integration.domain_name}
              />
            </IntegrationCard>
          ))}
      </IntegrationContainer>
    </FullContainer>
  );
};

export default TryAnotherIntegration;
