import React, { useState, useEffect } from "react";
import Context from "./Context";

interface Props {
  children: JSX.Element;
}

function useLocalStorage(key: string, initialValue: any) {
  const readValue = () => {
    // Prevent build error "window is undefined" but keep keep working
    if (typeof window === "undefined") {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  // State to store our value
  const [storedValue, setStoredValue] = useState(readValue);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    // just incase window is udnefined
    if (typeof window === "undefined") {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;

      window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(new Event("local-storage"));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  React.useEffect(() => {
    setStoredValue(readValue());
  }, []);

  React.useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("local-storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("local-storage", handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
}

const ContextProvider = ({ children }: Props) => {
  const [hasEnteredEmail, setHasEnteredEmail] = useLocalStorage(
    "hasEnteredEmail",
    false
  );

  const [emailEntered, setEmailEntered] = useLocalStorage("emailEntered", "");

  const [expandedBackground, setExpandedBackground] = useState<boolean>(false);

  const [hasSeenURLReqModal, setHasSeenURLReqModal] = useLocalStorage(
    "hasSeenURLReqModal",
    false
  );

  const [currentCategory, setCurrentCategory] = useState<string>("");

  const [currentURLForRun, setCurrentURLForRun] = useState<string>("");

  const [kickOffRun, setKickOffRun] = useState<boolean>(false);

  const [currentExecutionID, setCurrentExecutionID] = useState<string>("");

  useEffect(() => {
    // This function runs when the component is unmounted
    return () => {
      setExpandedBackground(false);
      setKickOffRun(false);
      setCurrentURLForRun("");
      setCurrentCategory("");
      setCurrentExecutionID("");
    };
  }, []);

  return (
    <Context.Provider
      value={{
        hasEnteredEmail,
        setEmailEntered,
        emailEntered,
        setHasEnteredEmail,
        expandedBackground,
        setExpandedBackground,
        hasSeenURLReqModal,
        setHasSeenURLReqModal,
        currentCategory,
        setCurrentCategory,
        currentURLForRun,
        setCurrentURLForRun,
        kickOffRun,
        setKickOffRun,
        currentExecutionID,
        setCurrentExecutionID,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
