import MappingCard, { CardHeader, CardRow } from "./MappingCard";
import { ReactComponent as MergeLogo } from "../assets/logos/MergeLogo.svg";
import { MergeActionButton } from "@merge-api/merge-javascript-shared";
import { Library } from "lucide-react";
import { capitalize } from "lodash";
import { FieldSchema } from "../models/Entities";
import styled from "styled-components";

interface Props {
  schemaMapping: FieldSchema[];
  dropdownComponent: JSX.Element;
  pathName?: string;
  category?: string;
}

const StyledMappingCard = styled(MappingCard)`
  height: fit-content;
`;

const CommonModelMappingCardInput = ({
  schemaMapping,
  dropdownComponent,
  pathName,
  category,
}: Props) => {
  return (
    <StyledMappingCard>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <MergeLogo />
          {dropdownComponent}
        </div>
        <MergeActionButton
          iconLeft={<Library size={12} />}
          className="ml-3"
          onClick={() => {
            window.open(
              `https://docs.merge.dev/${category}/${pathName}/`,
              "_blank"
            );
          }}
        >
          View docs
        </MergeActionButton>
      </CardHeader>
      <>
        {Array.from({ length: schemaMapping.length }, (_, index) => (
          <CardRow key={index}>
            <div className="d-flex align-items-center">
              <div className="text-md-mono mr-3 text-truncate">
                {schemaMapping[index]?.field}
              </div>
              <div className="text-gray-50 text-sm semibold">
                {capitalize(schemaMapping[index]?.type)}
              </div>
            </div>
          </CardRow>
        ))}
      </>
    </StyledMappingCard>
  );
};

export default CommonModelMappingCardInput;
