import { createGlobalStyle } from "styled-components";
import "./dashkit/theme.min.css";
import "./tailwind/whitespace.css";
import "./scss/_layout.scss"
import "./feather/feather.css";
import "@merge-api/merge-javascript-shared/src/styles/design-system-styles.scss";

const GlobalStyle = createGlobalStyle`
`;

export default GlobalStyle;