import styled, { keyframes } from "styled-components";
import { ChevronsRight } from "lucide-react";
import Context from "../../context/Context";
import { useContext } from "react";

interface Props {
  noLongerRendered: boolean;
  isFinalStage?: boolean;
  inputContainerHeight?: number;
}

const pulse = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
`;

const AbsoluteContainer = styled.div<Props>`
  position: absolute;
  ${(props) => (props.isFinalStage ? `` : `top: 0;`)}
  ${(props) => (props.isFinalStage ? `right: -10px;` : `right: -10px;`)}
  height: 100%;
  display: flex;
  padding-right: 0 !important;
  flex-direction: column;
  align-items: flex-end;
  opacity: ${(props) => (props.noLongerRendered ? 0 : 1)};
  transition: opacity 0.5s ease-in-out;
`;

const RightFade = styled.div`
  background: linear-gradient(90deg, rgba(245, 247, 250, 0) 0%, #f5f7fa 66.9%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 100%;
`;

const AnimatedChevronsRight = styled(ChevronsRight)`
  animation: ${pulse} 2s infinite;
`;

const ScrollRightFade = ({ noLongerRendered, isFinalStage = false }: Props) => {
  const { hasEnteredEmail } = useContext(Context);

  const shouldRenderChevrons =
    !isFinalStage || (isFinalStage && hasEnteredEmail);

  return (
    <AbsoluteContainer
      isFinalStage={isFinalStage}
      noLongerRendered={noLongerRendered}
    >
      <RightFade>
        {shouldRenderChevrons && (
          <AnimatedChevronsRight className="mb-5" size={16} />
        )}
      </RightFade>
    </AbsoluteContainer>
  );
};

export default ScrollRightFade;
