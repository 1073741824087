import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

const Container = styled.div`
  height: 200px;
  width: fit-content;
  align-items: center;
`;

const Bubble = styled.div`
  padding: 24px 36px 36px 36px;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: flex-start;
  border-radius: 14px;
  background: #ffffff;
  max-width: 626px;
  width: 100%;
  position: relative;
  will-change: opacity, transform;
`;

const StyledAnchor = styled.a`
  color: --var(--blue40) !important;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    color: --var(--blue60) !important;
  }
`;

type Prompt = {
  key: string;
  text: JSX.Element;
  isRight: boolean;
};

const textPrompts: Prompt[] = [
  {
    key: "1",
    text: (
      <div>
        Did you know Merge released 150 integrations in our first 2 years as a
        company?
      </div>
    ),
    isRight: false,
  },
  {
    key: "2",
    text: (
      <div>
        Merge is the first unified API company, meaning we aggregate data from
        multiple supported integrations, normalize that data, manage
        authentication and authorization, and sync the data continuously.
      </div>
    ),
    isRight: true,
  },
  {
    key: "3",
    text: (
      <div>
        Merge is SOC 2 Type II compliant, ISO 27001 certified, and HIPAA for
        US-healthcare data. We also give you control over data residency,
        including options to store data in the EU and options for
        single-tenancy.
      </div>
    ),
    isRight: false,
  },
  {
    key: "4",
    text: (
      <div>
        Not every customer requires the same access to data and they may be
        reluctant to grant access. Unified APIs should give your developers and
        your customers control of which data is accessed via the Unified API.
      </div>
    ),
    isRight: true,
  },
  {
    key: "5",
    text: <div>Our original mascot is a Manta Ray!</div>,
    isRight: false,
  },
  { key: "6", text: <div>We almost named Merge MRG...</div>, isRight: false },
  {
    key: "7",
    text: (
      <div>
        We’re a Unified API that allows deep customizability. Features like
        Field Mapping allow you to add additional fields to our Common Models,
        and Remote Fields allow you to read and write custom fields!
      </div>
    ),
    isRight: true,
  },
  {
    key: "8",
    text: (
      <div>
        We’re hiring! See if our{" "}
        <StyledAnchor href="https://merge.dev/careers" target="_blank">
          opportunities
        </StyledAnchor>{" "}
        are right for you.
      </div>
    ),
    isRight: false,
  },
  {
    key: "9",
    text: (
      <div>
        Our co-founders, Gil and Shensi, are best friends from freshman year of
        college!
      </div>
    ),
    isRight: true,
  },
];
const SpeechBubbles = () => {
  const [prevPrompt, setPrevPrompt] = useState<Prompt | null>(null);
  const [currentPrompt, setCurrentPrompt] = useState<Prompt>(
    textPrompts[Math.floor(Math.random() * textPrompts.length)]
  );

  const getRandomPrompt = () => {
    const filteredPrompts = textPrompts.filter(
      (prompt) => prompt.key !== prevPrompt?.key
    );
    const randomIndex = Math.floor(Math.random() * filteredPrompts.length);
    const selectedPrompt = filteredPrompts[randomIndex];
    setPrevPrompt(currentPrompt);
    setCurrentPrompt(selectedPrompt);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeout(() => getRandomPrompt(), 2000);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <Container className="d-flex w-100 flex-column">
      <SwitchTransition>
        <CSSTransition
          key={currentPrompt?.key}
          timeout={200}
          nodeRef={nodeRef}
          appear
          classNames="shake"
          addEndListener={(done) => {
            if (nodeRef.current) {
              nodeRef.current.addEventListener("transitionend", done, false);
            }
          }}
        >
          <Bubble
            className={
              currentPrompt?.isRight
                ? "speech-bubble-right"
                : "speech-bubble-left"
            }
            ref={nodeRef}
          >
            <h3 className="mb-3">Did you know?</h3>
            {currentPrompt?.text}
          </Bubble>
        </CSSTransition>
      </SwitchTransition>
    </Container>
  );
};

export default SpeechBubbles;
