import styled from "styled-components";
import { Form } from "react-bootstrap";
import { MergeFormButton } from "@merge-api/merge-javascript-shared";
import { useState, FormEvent, useRef, useContext } from "react";
import Context from "../../context/Context";
import hubspotSignUp, {
  getDomainName,
  validateEmail,
} from "../../models/Helpers";
import { fetchWithoutAuth } from "../../api-client/APIClient";
import { CheckLLMStatus, ModelMapping } from "../../models/Entities";

interface Props {
  setIntegrationMappings: React.Dispatch<
    React.SetStateAction<Record<string, ModelMapping>>
  >;
}

const AbsoluteContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 205;
  width: 100%;
`;

const RemovePaddingOverlay = styled.div`
  bottom: 0;
  left: 0;
  height: 24px;
  width: 100%;
  z-index: 206;
  background: #f5f7fa;
  position: absolute;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 24px 24px;
  border-radius: 8px;
`;

const Fade = styled.div`
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #f5f7fa 66.9%);
  width: 100%;
  height: 75px;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray10);
  padding: 20px;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
`;

const SameBackgroundCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #f5f7fa;
`;

const EmailFormContainer = styled.div`
  max-width: 343px;
  width: 100%;
`;

const BlackLink = styled.a`
  color: #000000;
  text-decoration: none;
`;

const EmailAddressCTA = ({ setIntegrationMappings }: Props) => {
  const {
    setHasEnteredEmail,
    setEmailEntered,
    setExpandedBackground,
    currentExecutionID,
    currentCategory,
    currentURLForRun,
  } = useContext(Context);

  const [emailError, setEmailError] = useState<boolean>(false);

  const emailInputRef = useRef<HTMLInputElement>(null);

  const emailErrorMessage = "Please enter a valid work email address.";

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const formData = {
      emailInput: emailInputRef?.current?.value ?? "",
    };
    if (!validateEmail(formData.emailInput)) {
      setEmailError(true);
    } else {
      submitEmailPOST(formData.emailInput, currentExecutionID);
    }
  };

  const submitEmailPOST = (signup_email: string, execution_id: string) => {
    fetchWithoutAuth({
      path: `/ai/send-llm-email/${execution_id}`,
      method: "POST",
      body: {
        signup_email: signup_email,
      },
      onResponse: (data: CheckLLMStatus) => {
        setEmailError(false);
        setEmailEntered(signup_email);
        setExpandedBackground(true);
        setHasEnteredEmail(true);
        setIntegrationMappings(data.mappings);
        hubspotSignUp(
          {
            email: signup_email,
            ai_category: currentCategory,
            ai_integration_name: getDomainName(currentURLForRun),
            ai_docs_url: currentURLForRun,
          },
          "6749545e-0077-47b5-a89b-62ab625df7f7"
        );
      },
      onError: () => {
        setEmailError(true);
      },
    });
  };

  return (
    <div>
      <AbsoluteContainer>
        <FlexContainer>
          <Fade />
          <SameBackgroundCard>
            <Card>
              <h4 className="mt-5 mb-1">
                Enter your email address to see Blueprint results for all Common
                Models and fields.
              </h4>
              <div className="mb-6 text-sm text-gray-50">
                By submitting your work email address, you agree to receive
                marketing emails from Merge. See our{" "}
                <BlackLink
                  href="https://merge.dev/legal/privacy-policy"
                  target="_blank"
                >
                  Privacy Policy
                </BlackLink>{" "}
                for more information.
              </div>

              <Form
                className={`w-100 mb-9`}
                onSubmit={onSubmit}
                id="blueprint-email-form"
              >
                <div className="d-flex w-100 justify-content-center align-items-start">
                  <EmailFormContainer className="mr-4 w-100">
                    <Form.Control
                      className="text-truncate shadow-md"
                      as="input"
                      name="urlInput"
                      type="text"
                      placeholder="Email address"
                      ref={emailInputRef}
                      style={{ height: "40px", width: "100%" }}
                      isInvalid={!!emailError}
                    />
                    {emailError && (
                      <Form.Control.Feedback
                        className="text-sm text-red-50 mt-1 is-invalid-absolute"
                        type="invalid"
                      >
                        {emailErrorMessage}
                      </Form.Control.Feedback>
                    )}
                  </EmailFormContainer>
                  <MergeFormButton size="medium" color="black" type="submit">
                    See more
                  </MergeFormButton>
                </div>
              </Form>
            </Card>
          </SameBackgroundCard>
        </FlexContainer>
      </AbsoluteContainer>
      <RemovePaddingOverlay />
    </div>
  );
};

export default EmailAddressCTA;
