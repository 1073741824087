import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const StyledRow = styled(Row)`
  && {
    margin: 0 auto;
    width: 100%;
    display: flex;
    height: 100%;
    overflow: visible;
  }
`;

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1196px;
  margin: 0 auto;
`;

export const StaticPageContainer = ({ children }: Props) => (
  <StyledRow className="justify-content-center">
    <Container>{children}</Container>
  </StyledRow>
);
