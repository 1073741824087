import preparingData from "../../assets/animations/preparing.json";
import Lottie from "react-lottie";

const PreparingComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: preparingData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={200}
      width={206}
      isClickToPauseDisabled={true}
    />
  );
};

export default PreparingComponent;
