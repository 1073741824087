import styled, { css } from "styled-components";

interface Props {
  children: JSX.Element | JSX.Element[];
  fullHeight?: boolean;
}

interface GrayBlockProps {
  isWide?: boolean;
}

interface IntegrationMappingRowProps {
  isHover?: boolean;
}

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  min-width: fit-content;
  align-items: flex-start;
  padding: 10px 12px;
  height: 52px;
  border-bottom: 0.5px solid #dce2ea;
`;

export const CardRow = styled.div<IntegrationMappingRowProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 44px;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 16px 10px 12px;
  border-bottom: 0.5px solid #dce2ea;
  transition: background-color 0.15s ease-in-out;
  background-color: #ffffff;
  ${(props) =>
    props.isHover &&
    css`
      &:hover {
        background-color: #f2f4f7;
      }
    `}
`;

export const GrayBlock = styled.div<GrayBlockProps>`
  border-radius: 2px;
  background: #dce2ea;
  width: 100%;
  height: 12px;
  max-width: ${(props) => (props.isWide ? "170px" : "70px")};
`;

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  & ${CardRow}:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const MappingCard = ({ children }: Props) => {
  return <Card className="shadow-md">{children}</Card>;
};

export default MappingCard;
