import React from "react";
import { Modal, ModalProps } from "react-bootstrap";
import classNames from "classnames";
import styled from "styled-components";
import { X } from "lucide-react";

type MergeModalProps = {
  show: boolean;
  title?: string | JSX.Element;
  nav?: JSX.Element;
  onHide?: () => void;
  children: React.ReactNode;
  centered?: boolean | null;
  dialogClassName?: string;
  bodyClassName?: string;
  ref?: any;
  fitContent?: boolean;
  /**
   * Render a large, extra large or small modal. When not provided, the modal is rendered with medium (default) size.
   */
  size?: "sm" | "lg" | "xl";
  /**
   * For styled-components support. Forwarded to <Modal> wrapper after dialogClassName.
   */
  className?: string;
} & Pick<ModalProps, "backdrop">;

const Header = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;
  align-items: center !important;
  padding-bottom: 21px;
  padding-left: 24px;
  padding-top: 24px;
  padding-right: 24px;
`;

const Body = styled.div`
  padding-top: 21px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
`;

const StyledX = styled(X)`
  color: var(--gray40);
  &:hover {
    color: var(--slate90);
    cursor: pointer;
  }
`;

function MergeModal(props: MergeModalProps) {
  return (
    <Modal
      show={props.show ?? false}
      animation={false}
      onHide={props.onHide}
      centered={props.centered ?? true}
      dialogClassName={classNames(props.dialogClassName, props.className)}
      ref={props.ref}
      className={classNames(
        "modal-shadow",
        props.fitContent ? "fit-content" : ""
      )}
      size={props.size}
      backdrop={props.backdrop}
    >
      {props.title && (
        <Header className="border-b">
          <h4> {props.title}</h4> <StyledX size={16} onClick={props.onHide} />
        </Header>
      )}
      <Body className={props.bodyClassName}>{props.children}</Body>
    </Modal>
  );
}

export default MergeModal;
