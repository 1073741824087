import BackgroundContainer from "./pages/BackgroundContainer";
import GlobalStyle from "./styles/globalStyles";

// const StyledOtherComponents = styled.div`
//   height: 500px;
//   background-color: #000000;
//   width: 100%;
// `;

// const StyledOtherComponents2 = styled.div`
//   height: 500px;
//   background-color: #ffffff;
//   width: 100%;
// `;

// const StyledContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

function App() {
  return (
    <>
      <GlobalStyle />
      <BackgroundContainer />
    </>
  );
}

export default App;
