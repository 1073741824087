import styled from "styled-components";
import MappingCard, {
  Card,
  CardHeader,
  CardRow,
  GrayBlock,
} from "../MappingCard";
import { MergeActionButton } from "@merge-api/merge-javascript-shared";
import { ReactComponent as Arrow } from "../../assets/icons/Arrow12.svg";
import { Library } from "lucide-react";
import { ReactComponent as LeverIcon } from "../../assets/icons/LeverSquare.svg";
import { ReactComponent as MergeLogo } from "../../assets/logos/MergeLogo.svg";
import ScrollRightFade from "./ScrollRightFade";
import { useState, useRef, useEffect } from "react";

interface Props {
  noLongerRendered: boolean;
}

const MainContainer = styled.div<Props>`
  opacity: ${(props) => (props.noLongerRendered ? 0 : 1)};
  transition: opacity 1s ease-out;
  position: relative;
  width: 100%;
  height: 100%;
`;

const MappingContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  width: 100%;
  overflow-x: auto;
`;

const ArrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 52px;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 44px;
  align-items: center;
  justify-content: center;
`;

const MinWidthMappingCard = styled(Card)`
  min-width: 400px !important;
`;

const HardcodedInitialView = ({ noLongerRendered }: Props) => {
  const rowArray = Array.from({ length: 8 });
  const [overflowXAvailable, setOverflowXAvailable] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const overflow =
          containerRef.current.offsetWidth < containerRef.current.scrollWidth;
        setOverflowXAvailable(overflow);
      }
    };
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, []);

  const renderCardRow = (index: number) => (
    <CardRow key={"CardRow" + index}>
      <GrayBlock isWide className="mr-3" />
      <GrayBlock />
    </CardRow>
  );

  const renderArrowContainer = (index: number) => (
    <ArrowContainer key={"ArrowContainer" + index}>
      <Arrow />
    </ArrowContainer>
  );

  return (
    <MainContainer noLongerRendered={noLongerRendered}>
      <MappingContainer ref={containerRef}>
        <MinWidthMappingCard className="shadow-md">
          <CardHeader className="d-flex align-items-center">
            <LeverIcon />
            <div className="semibold ml-2">Lever</div>
          </CardHeader>
          <CardRow>
            <div className="text-md-mono mr-3 text-truncate">
              lever.integration.id.mapping[index].value
            </div>
            <div className="semibold text-sm">/endpoint</div>
          </CardRow>
          <>{rowArray.map((_, index) => renderCardRow(index))}</>
        </MinWidthMappingCard>
        <ArrowColumn>
          <>{rowArray.map((_, index) => renderArrowContainer(index))}</>
        </ArrowColumn>
        <MinWidthMappingCard className="shadow-md">
          <CardHeader className="align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <MergeLogo />
              <div className="semibold ml-2 mr-3 text-nowrap">
                Candidate Common Model
              </div>
            </div>
            <MergeActionButton
              iconLeft={<Library size={12} />}
              className="ml-3"
              onClick={() => {
                window.open("https://docs.merge.dev/ats/candidates/", "_blank");
              }}
            >
              View docs
            </MergeActionButton>
          </CardHeader>
          <CardRow>
            <div className="text-md-mono mr-3 text-truncate">first_name</div>
            <div className="semibold text-sm text-gray-50">String</div>
          </CardRow>
          <>{rowArray.map((_, index) => renderCardRow(index))}</>
        </MinWidthMappingCard>
      </MappingContainer>
      <ScrollRightFade noLongerRendered={!overflowXAvailable} />
    </MainContainer>
  );
};

export default HardcodedInitialView;
