export enum LLMStatus {
  LLM_MAPPING_EXECUTION_STATUS_IN_QUEUE = "IN_QUEUE",
  LLM_MAPPING_EXECUTION_STATUS_PULLING_API_DOCS = "PULLING_API_DOCS",
  LLM_MAPPING_EXECUTION_STATUS_PARSING_API_DOCS = "PARSING_API_DOCS",
  LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS = "MAPPING_MODELS",
  LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW = "RATE_LIMITED",
  LLM_MAPPING_EXECUTION_STATUS_APPROVED = "MAPPINGS_READY",
  LLM_MAPPING_EXECUTION_STATUS_FAILED = "FAILED",
}

export const LLM_STATUSES = [
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_IN_QUEUE,
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_PULLING_API_DOCS,
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_PARSING_API_DOCS,
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS,
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW,
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED,
  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_FAILED,
];

export enum LLMError {
  LLM_MAPPING_EXECUTION_ERROR_INVALID_URL = "INVALID_URL",
  LLM_MAPPING_EXECUTION_ERROR_CATEGORY_MISMATCH = "CATEGORY_MISMATCH",
  LLM_MAPPING_EXECUTION_ERROR_OTHER = "OTHER",
}

export const LLM_MAPPING_EXECUTION_ERRORS = [
  LLMError.LLM_MAPPING_EXECUTION_ERROR_INVALID_URL,
  LLMError.LLM_MAPPING_EXECUTION_ERROR_CATEGORY_MISMATCH,
  LLMError.LLM_MAPPING_EXECUTION_ERROR_OTHER,
];

export enum LLMCompletedStatuses {}

export interface KickOffLLM {
  "apply!": string;
  execution_id: string;
  category_models: Record<string, CategoryModel>;
}

export interface FieldSchema {
  field: string;
  type: string;
}

export interface CategoryModel {
  fields: { field: string; type: string }[];
  path: string;
}

export interface FieldMapping {
  api_endpoint_url: string;
  api_endpoint_response_traversal_path: string[];
}

export interface ModelMapping {
  [fieldName: string]: FieldMapping;
}

export interface CheckLLMStatus {
  "apply!": string;
  mappings: Record<string, ModelMapping>;
  status: LLMStatus;
  error: LLMError;
  place_in_line: number;
  is_currently_supported_integration: boolean;
}

export interface CompareResult {
  newIntegrationMappings: Record<string, ModelMapping>;
  newCategoryModels: Record<string, CategoryModel>;
  filteredOutFields: Record<string, CategoryModel>;
}

export interface FavIconResult {
  favIconURL: string;
  urlName: string;
}

export interface CategoryStatus {
  "apply!": string;
  api_docs_url: string;
  category: string;
  category_models: Record<string, CategoryModel>;
}

export interface ValidationErrors {
  invalidURL: boolean;
  nonSelectedCategory: boolean;
}
