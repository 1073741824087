import styled from "styled-components";
import { ReactComponent as Arrow } from "../assets/icons/Arrow12.svg";
import { LLMStatus, ModelMapping } from "../models/Entities";
import BabyLoadingIcon from "./loading/BabyLoadingIcon";
import { keyframes } from "styled-components";

interface Props {
  isLoading?: boolean;
  modelRowInput: number;
  modelMapping?: ModelMapping;
  status?: LLMStatus;
}

const ArrowColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 52px;
`;

const ArrowRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 44px;
  width: 56px;
  align-items: center;
  justify-content: center;
`;

//maintains same width as our arrow svg so it doesn't change the size of our boxes
const ArrowPlaceholder = styled.div`
  width: 25px;
`;

const loading = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const AnimationParentDiv = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0 16px;
`;

const TransitionArrow = styled(Arrow)`
  animation: ${loading} 0.2s ease-in;
  position: absolute;
  will-change: transform, opacity;
`;

const ArrowInput = ({ modelRowInput, modelMapping, status }: Props) => {
  return (
    <ArrowColumn>
      {modelMapping
        ? Object.keys(modelMapping)
            .sort()
            .map((key, index) => (
              <ArrowRow key={index}>
                {!modelMapping[key] ||
                !modelMapping[key].api_endpoint_response_traversal_path ? (
                  <ArrowPlaceholder />
                ) : (
                  <AnimationParentDiv>
                    <TransitionArrow />
                  </AnimationParentDiv>
                )}
              </ArrowRow>
            ))
        : Array.from({ length: modelRowInput }, (_, index) => (
            <ArrowRow key={index}>
              <div className="d-flex align-items-center">
                {status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED ? (
                  <ArrowPlaceholder />
                ) : (
                  <BabyLoadingIcon
                    isRendered={
                      !LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED
                    }
                  />
                )}
              </div>
            </ArrowRow>
          ))}
    </ArrowColumn>
  );
};

export default ArrowInput;
