import { APICategory } from "@merge-api/merge-javascript-shared";
import { capitalize } from "lodash";
import {
  CategoryModel,
  CompareResult,
  FavIconResult,
  ModelMapping,
} from "../models/Entities";

export const MOBILE_RESPONSIVE = "602px";

export const displayNameForAPICategory = (category: string): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "Marketing Automation";
    case APICategory.filestorage:
      return "File Storage";
    default:
      throw new Error(`Unhandled APICategory value: ${category}`);
  }
};

export const shortDisplayNameForAPICategory = (category: string): string => {
  switch (category) {
    case APICategory.ats:
      return "ATS";
    case APICategory.hris:
      return "HRIS";
    case APICategory.accounting:
      return "Accounting";
    case APICategory.ticketing:
      return "Ticketing";
    case APICategory.crm:
      return "CRM";
    case APICategory.mktg:
      return "MKTG";
    case APICategory.filestorage:
      return "File Storage";
    default:
      throw new Error(`Unhandled APICategory value: ${category}`);
  }
};

export const prefillCategorySelectURL = (category: string): string => {
  switch (category) {
    case APICategory.ats:
      return "https://hire.lever.co/developer/documentation";
    case APICategory.hris:
      return "https://developers.adp.com/articles/api/workers-v2-api";
    case APICategory.accounting:
      return "https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account";
    case APICategory.ticketing:
      return "https://developers.intercom.com/intercom-api-reference/reference/introduction";
    case APICategory.crm:
      return "https://developers.hubspot.com/docs/api/overview";
    case APICategory.mktg:
      return "https://developer.infusionsoft.com/docs/rest/";
    case APICategory.filestorage:
      return "https://learn.microsoft.com/en-us/onedrive/developer/?view=odsp-graph-online";
    default:
      throw new Error(
        `Unhandled APICategory URL for ${category} - please enter your own URL`
      );
  }
};

export const defaultCategoryUrls = [
  "https://hire.lever.co/developer/documentation",
  "https://developers.adp.com/articles/api/workers-v2-api",
  "https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account",
  "https://developers.intercom.com/intercom-api-reference/reference/introduction",
  "https://developers.hubspot.com/docs/api/overview",
  "https://developer.infusionsoft.com/docs/rest/",
  "https://learn.microsoft.com/en-us/onedrive/developer/?view=odsp-graph-online",
];

export const fetchFaviconAndDomain = (url: string): FavIconResult => {
  const urlObj = new URL(url);
  const domainParts = urlObj.hostname.split(".");
  const domain =
    domainParts.length > 1 ? domainParts.slice(-2).join(".") : domainParts[0];
  const favIconURL = `${urlObj.protocol}//${domain}/favicon.ico`;
  const urlName = capitalize(domain);

  return { favIconURL, urlName };
};

export function getDomainName(url: string): string {
  try {
    const hostname = new URL(url).hostname.toLowerCase();
    const domainParts = hostname.split(".");
    const domainName =
      domainParts.length > 1
        ? domainParts[domainParts.length - 2]
        : domainParts[0];
    return domainName;
  } catch (error) {
    console.error(`Invalid URL: ${url}`, error);
    return "";
  }
}

export function isValidUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  if (url.protocol !== "http:" && url.protocol !== "https:") {
    return false;
  }

  if (!url.hostname) {
    return false;
  }
  const tldRegex = /^.+\.([a-z\.]{2,6})$/;
  const match = tldRegex.exec(url.hostname);
  if (!match || !match[1]) {
    return false;
  }

  return true;
}
export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

interface SignUpData {
  email: string;
  ai_category?: string;
  ai_integration_name?: string;
  ai_docs_url?: string;
}

export default function hubspotSignUp(signUpData: SignUpData, formId: string) {
  const portalId = "20608300";
  const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;
  const isBrowser = typeof window !== "undefined";
  const pageUri = isBrowser ? window.location.href : null;
  const pageName = isBrowser ? document.title : null;
  fetch(postUrl, {
    method: "post",
    body: JSON.stringify({
      submittedAt: Date.now(),
      fields: [
        {
          name: "email",
          value: signUpData.email,
        },
        {
          name: "ai_category",
          value: signUpData.ai_category,
        },
        {
          name: "ai_integration_name",
          value: signUpData.ai_integration_name,
        },
        {
          name: "ai_docs_url",
          value: signUpData.ai_docs_url,
        },
      ],
      context: {
        pageUri: pageUri,
        pageName: pageName,
      },
    }),
    headers: new Headers({
      "Content-Type": "application/json",
      Accept: "application/json, application/xml, text/plain, text/html, *.*",
    }),
  });
}

export const compareCategoryAndIntegration = (
  categoryModels: Record<string, CategoryModel>,
  integrationMappings: Record<string, ModelMapping>
): CompareResult => {
  const newIntegrationMappings: Record<string, ModelMapping> = {};
  const newCategoryModels: Record<string, CategoryModel> = {};
  const filteredOutFields: Record<string, CategoryModel> = {};

  for (const [key, modelMapping] of Object.entries(integrationMappings)) {
    const categoryModel = categoryModels[key];

    if (!categoryModel) {
      continue;
    }

    if (!modelMapping || Object.keys(modelMapping).length === 0) {
      newIntegrationMappings[key] = {};
      newCategoryModels[key] = { ...categoryModel };
      filteredOutFields[key] = { ...categoryModel, fields: [] };
      continue;
    }

    newIntegrationMappings[key] = {};
    newCategoryModels[key] = { ...categoryModel, fields: [] };
    filteredOutFields[key] = { ...categoryModel, fields: [] };

    for (const [fieldKey, fieldMapping] of Object.entries(
      modelMapping
    ).sort()) {
      if (!fieldMapping || !fieldMapping.api_endpoint_url) {
        continue;
      }

      const categoryField = categoryModel.fields.find(
        (field) => field.field === fieldKey
      );

      if (!categoryField) {
        continue;
      }

      newIntegrationMappings[key][fieldKey] = fieldMapping;
      newCategoryModels[key].fields.push(categoryField);
    }

    filteredOutFields[key].fields = categoryModel.fields.filter(
      (field) => !newCategoryModels[key].fields.includes(field)
    );

    if (filteredOutFields[key].fields.length === categoryModel.fields.length) {
      filteredOutFields[key].fields = [];
    }

    newCategoryModels[key].fields.sort((a, b) =>
      a.field.localeCompare(b.field)
    );

    filteredOutFields[key].fields.sort((a, b) =>
      a.field.localeCompare(b.field)
    );
  }

  return {
    newIntegrationMappings,
    newCategoryModels,
    filteredOutFields,
  };
};
