import styled from "styled-components";

interface Props {
  noLongerRendered: boolean;
}

const AbsoluteContainer = styled.div<Props>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 200;
  width: inherit;
  transition: opacity 1s ease-out;
  opacity: ${(props) => (props.noLongerRendered ? 0 : 1)};
`;

const Fade = styled.div`
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #f5f7fa 66.9%);
  width: 100%;
  height: 200px;
`;

const InitialViewFade = ({ noLongerRendered }: Props) => {
  return (
    <AbsoluteContainer noLongerRendered={noLongerRendered}>
      <Fade />
    </AbsoluteContainer>
  );
};

export default InitialViewFade;
