import confetti from "../../assets/animations/success.json";
import Lottie from "react-lottie";

const SuccessfulSubmission = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      width={80}
      height={80}
      isClickToPauseDisabled={true}
    />
  );
};

export default SuccessfulSubmission;
