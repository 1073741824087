import React, { useState, useEffect } from "react";
import PreparingComponent from "./PreparingComponent";
import SpeechBubbles from "./SpeechBubbles";

interface PreparingLoadingStageProps {
  onPreparingComplete: () => void;
}

const PreparingLoadingStage: React.FC<PreparingLoadingStageProps> = ({
  onPreparingComplete,
}) => {
  const [showSpeechBubbles, setShowSpeechBubbles] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpeechBubbles(true);
      onPreparingComplete();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {!showSpeechBubbles && <PreparingComponent />}
      {showSpeechBubbles && <SpeechBubbles />}
    </>
  );
};

export default PreparingLoadingStage;
