import styled from "styled-components";
import { MergeFormButton } from "@merge-api/merge-javascript-shared";
import { useContext, useState, useEffect } from "react";
import ConfettiComponent from "./ConfettiComponent";
import MergeModal from "../merge-components/MergeModal";
import { fetchWithoutAuth } from "../../api-client/APIClient";
import Context from "../../context/Context";

const SubmitIntegrationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  padding: 24px;
  position: relative;
`;

const CloseButtonText = styled.div`
  cursor: pointer;
`;

const SubmitIntegration = () => {
  const [displayConfetti, setDisplayConfetti] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { currentExecutionID } = useContext(Context);

  const submitIntegrationPOST = (execution_id: string) => {
    fetchWithoutAuth({
      path: `/ai/request-integration/${execution_id}`,
      method: "POST",
      onResponse: () => {
        setShowModal(false);
        setDisplayConfetti(true);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    if (displayConfetti) {
      setShowModal(false);
    }
  }, [displayConfetti]);

  return (
    <div>
      <SubmitIntegrationContainer className="shadow-md rounded-lg">
        <div className="d-flex flex-column align-content-start">
          <h4 className="mb-2">
            {displayConfetti
              ? "Thanks for your submission! We will get back to you soon."
              : "Want to see this integration in Merge?"}
          </h4>
          {displayConfetti ? (
            <div className="text-sm text-gray-60">
              Note that we are receiving an incredibly high volume of
              submissions. As quality is our number one priority, while this
              product is still in beta, we will be running a rigorous review
              process on all new integrations.
            </div>
          ) : (
            <div className="text-lg">
              Submit this AI generated mapping to the Merge team.
            </div>
          )}
        </div>
        {!displayConfetti && (
          <MergeFormButton
            className="align-self-center ml-2"
            color="black"
            size="medium"
            buttonIcon="chevronIcon"
            onClick={() => setShowModal(true)}
          >
            Submit
          </MergeFormButton>
        )}
        {displayConfetti && <ConfettiComponent />}
      </SubmitIntegrationContainer>
      <MergeModal
        show={showModal}
        size="sm"
        onHide={() => setShowModal(false)}
        fitContent={true}
        title="Submit Blueprint Result"
      >
        <div>
          Submission of this proposed integration does not guarantee it will be
          built or that you will have exclusive use of it.
        </div>
        <MergeFormButton
          className="mt-6 mb-6"
          stretch
          size="medium"
          color="black"
          onClick={() => {
            submitIntegrationPOST(currentExecutionID);
          }}
        >
          Submit
        </MergeFormButton>
        <CloseButtonText
          className="d-flex text-gray-90 medium justify-content-center align-items-center"
          onClick={() => setShowModal(false)}
        >
          Cancel
        </CloseButtonText>
      </MergeModal>
    </div>
  );
};

export default SubmitIntegration;
