import confetti from "../../assets/animations/confetti.json";
import Lottie from "react-lottie";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
`;

const ConfettiComponent = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: confetti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Container>
      <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
    </Container>
  );
};

export default ConfettiComponent;
