import styled from "styled-components";
import { FormEvent, useRef, useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { MergeFormButton } from "@merge-api/merge-javascript-shared";
import hubspotSignUp, {
  getDomainName,
  validateEmail,
} from "../../models/Helpers";
import Context from "../../context/Context";
import SuccessfulSubmission from "./SuccessfulSubmission";
import { fetchWithoutAuth } from "../../api-client/APIClient";
import TryAnotherIntegration from "./TryAnotherIntegration";

const EmailFormContainer = styled.div`
  max-width: 343px;
  width: 100%;
`;

const TryAnotherIntContainer = styled.div`
  align-self: flex-end;
  width: 100%;
  margin-top: auto;
`;

const BlackLink = styled.a`
  color: #000000;
  text-decoration: none;
`;

const ProcessRequest = () => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const {
    emailEntered,
    setEmailEntered,
    setHasEnteredEmail,
    currentExecutionID,
    currentCategory,
    currentURLForRun,
  } = useContext(Context);

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<boolean>(false);

  const emailErrorMessage = "Please enter a valid work email address.";

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    const formData = {
      emailInput: emailInputRef?.current?.value ?? "",
    };
    if (!validateEmail(formData.emailInput)) {
      setEmailError(true);
    } else {
      submitBlueprintPOST(formData.emailInput, currentExecutionID);
    }
  };

  const submitBlueprintPOST = (entered_email: string, execution_id: string) => {
    fetchWithoutAuth({
      path: `/ai/send-llm-email/${execution_id}`,
      method: "POST",
      body: {
        signup_email: entered_email,
      },
      onResponse: () => {
        setEmailError(false);
        setEmailEntered(entered_email);
        setHasEnteredEmail(true);
        setHasSubmitted(true);
        hubspotSignUp(
          {
            email: entered_email,
            ai_category: currentCategory,
            ai_integration_name: getDomainName(currentURLForRun),
            ai_docs_url: currentURLForRun,
          },
          "6749545e-0077-47b5-a89b-62ab625df7f7"
        );
      },
      onError: () => {
        setEmailError(true);
      },
    });
  };

  return (
    <div className="h-100 w-100 d-flex flex-column">
      {hasSubmitted ? (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <SuccessfulSubmission />
          <h4 className="mb-2 mt-4">
            Your request has been submitted successfully!{" "}
          </h4>
          <div>
            Thank you for your patience, you will receive your results shortly.
          </div>
        </div>
      ) : (
        <div className="mt-4 d-flex flex-column align-items-center justify-content-center">
          <h4 className="mb-2">
            Blueprint is working to process your request.
          </h4>
          <div className="text-md">
            We're being temporarily rate limited by our AI infrastructure
            provider as they are experiencing heavy demand.
          </div>
          <div className="text-md">
            Please provide your work email address and we will send you the
            results as soon as they are ready.
          </div>
          <Form
            className={`w-100 mt-6`}
            onSubmit={onSubmit}
            id="blueprint-email-form"
          >
            <div className="d-flex w-100 justify-content-center align-items-start">
              <EmailFormContainer className="mr-4 w-100">
                <Form.Control
                  className="text-truncate shadow-md"
                  as="input"
                  name="urlInput"
                  type="text"
                  placeholder={"Email address"}
                  defaultValue={emailEntered}
                  ref={emailInputRef}
                  style={{ height: "40px", width: "100%" }}
                  isInvalid={!!emailError}
                />
                {emailError && (
                  <Form.Control.Feedback
                    className="text-sm text-red-50 mt-1 is-invalid-absolute"
                    type="invalid"
                  >
                    {emailErrorMessage}
                  </Form.Control.Feedback>
                )}
              </EmailFormContainer>
              <MergeFormButton size="medium" color="black" type="submit">
                Submit
              </MergeFormButton>
            </div>
          </Form>
        </div>
      )}
      <TryAnotherIntContainer>
        <div className="d-flex flex-column w-100 justify-content-center align-items-center">
          {hasSubmitted ? (
            <TryAnotherIntegration
              className="mb-6 mt-9 w-100"
              category={currentCategory}
            />
          ) : (
            <div className="mb-3 text-gray-50 text-sm">
              By submitting your email address, you agree to receive marketing
              emails from Merge. See our{" "}
              <BlackLink
                href="https://merge.dev/legal/privacy-policy"
                target="_blank"
              >
                Privacy Policy
              </BlackLink>{" "}
              for more information.
            </div>
          )}
        </div>
      </TryAnotherIntContainer>
    </div>
  );
};

export default ProcessRequest;
