import { MergeAlert } from "@merge-api/merge-javascript-shared";
import {
  CheckLLMStatus,
  LLMError,
  ValidationErrors,
} from "../../models/Entities";
import { useEffect } from "react";
import styled from "styled-components";

interface Props {
  status?: CheckLLMStatus;
  errors: ValidationErrors;
  stopLoadingState: () => void;
}

const ErrorContainer = styled.div`
  height: fit-content;
  width: 100%;
`;

const errorMessages = {
  [LLMError.LLM_MAPPING_EXECUTION_ERROR_INVALID_URL]: (
    <div>
      Invalid URL. Please try again with a valid <b>API documentation URL.</b>
    </div>
  ),
  [LLMError.LLM_MAPPING_EXECUTION_ERROR_CATEGORY_MISMATCH]: (
    <div>
      Selected category does not match API documentation URL. Please try again.
    </div>
  ),
  [LLMError.LLM_MAPPING_EXECUTION_ERROR_OTHER]: (
    <div>
      We regret to inform you that we were unable to generate the desired
      result. This feature is in beta and we will be improving upon it in the
      coming weeks.
    </div>
  ),
};

const validationErrorMessages: { [key: string]: JSX.Element } = {
  invalidURL: (
    <div>
      Invalid URL. Please try again with a valid <b>API documentation URL.</b>
    </div>
  ),
  nonSelectedCategory: (
    <div>No category selected. Please select a category and try again.</div>
  ),
};

const ErrorComponent = ({ status, errors, stopLoadingState }: Props) => {
  useEffect(() => {
    stopLoadingState();
  }, []);

  const validationAlerts = Object.entries(errors).reduce(
    (acc: JSX.Element[], [key, value]) => {
      if (value === true) {
        acc.push(
          <MergeAlert className="mb-9" color="red" showWarningIcon key={key}>
            {validationErrorMessages[key]}
          </MergeAlert>
        );
      }
      return acc;
    },
    []
  );

  const errorStatus =
    status?.error ?? LLMError.LLM_MAPPING_EXECUTION_ERROR_OTHER;

  const statusAlert = status ? (
    <MergeAlert className="mb-9" color="red" showWarningIcon>
      {errorMessages[errorStatus]}
    </MergeAlert>
  ) : null;

  return (
    <div className="d-flex flex-colum w-100">
      <ErrorContainer>
        {validationAlerts}
        {statusAlert}
      </ErrorContainer>
    </div>
  );
};

export default ErrorComponent;
