import styled from "styled-components";
import { ChevronDown } from "lucide-react";
import Context from "../context/Context";
import { useContext, useState, useEffect } from "react";

interface Props {
  handleSeeMoreView: () => void;
}

interface VisibilityProps {
  isVisible: boolean;
}

const AbsoluteContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 1196px;
`;

const MainCard = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Fade = styled.div`
  background: linear-gradient(180deg, rgba(245, 247, 250, 0) 0%, #f5f7fa 66.9%);
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
`;

const SeeMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const ClickRow = styled.div<VisibilityProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: #f5f7fa;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
`;

const Click = styled.div<VisibilityProps>`
  cursor: pointer;
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 1s ease-in-out;
  height: fit-content;
  width: fit-content;
`;

const ClickContainer = styled.div`
  height: 61px;
`;

const SeeMoreView = ({ handleSeeMoreView }: Props) => {
  const { setExpandedBackground, expandedBackground } = useContext(Context);
  const [isClickVisible, setIsClickVisible] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsClickVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AbsoluteContainer>
      <MainCard>
        <SeeMoreContainer>
          <Fade className="test" />
          <ClickRow isVisible={!expandedBackground}>
            <ClickContainer>
              {isClickVisible && (
                <Click
                  isVisible={isClickVisible}
                  className="d-flex flex-column align-items-center"
                  onClick={() => {
                    setExpandedBackground(true);
                    handleSeeMoreView();
                  }}
                >
                  <div className="text-black semibold text-nowrap">
                    See More
                  </div>
                  <ChevronDown size={24} />
                </Click>
              )}
            </ClickContainer>
          </ClickRow>
        </SeeMoreContainer>
      </MainCard>
    </AbsoluteContainer>
  );
};

export default SeeMoreView;
