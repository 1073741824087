import MappingCard, { CardHeader, CardRow } from "./MappingCard";
import { ReactComponent as AltFavIcon } from "../assets/icons/AlternativeFavicon.svg";
import { FavIconResult, LLMStatus, ModelMapping } from "../models/Entities";
import { useState, useEffect } from "react";
import styled, { css } from "styled-components";

interface Props {
  modelRowInput: number;
  modelMapping?: ModelMapping;
  status?: LLMStatus;
  urlInfo: FavIconResult | null;
}

const EmptyContainer = styled.div`
  display: flex;
  padding: 10px 16px 10px 12px;
  min-width: 530px;
`;

const BlackDiv = styled.div`
  background: #000000;
  height: 26px;
  width: 26px;
`;

function constructTraversalPath(traversalPath: string[] | null | undefined): {
  initial_string: string;
  final_value: string;
} {
  if (!traversalPath) {
    return { initial_string: "", final_value: "" };
  }

  let initial_string = "";
  let final_value = "";

  traversalPath.forEach((element, index) => {
    if (
      index !== 0 &&
      traversalPath[index - 1] !== "$WILDCARD_ARRAY_INDEX" &&
      element !== "$WILDCARD_ARRAY_INDEX"
    ) {
      initial_string += ".";
    }

    if (element === "$WILDCARD_ARRAY_INDEX") {
      initial_string += "[index]";
      if (index < traversalPath.length - 1) {
        initial_string += ".";
      }
    } else {
      initial_string += element;
    }
  });

  const lastIndex = initial_string.lastIndexOf("[index].");
  if (lastIndex !== -1) {
    final_value = initial_string.slice(lastIndex + "[index].".length);
    initial_string = initial_string.slice(0, lastIndex + "[index].".length);
  }

  return { initial_string, final_value };
}

const IntegrationMappingCardInput = ({
  modelRowInput,
  modelMapping,
  status,
  urlInfo,
}: Props) => {
  const [imageError, setImageError] = useState(false);

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <MappingCard fullHeight={modelMapping === undefined}>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center mr-6">
          {!imageError ? (
            <img
              src={urlInfo?.favIconURL}
              alt="Favicon"
              width={25}
              height={25}
              onError={handleImageError}
            />
          ) : (
            <BlackDiv className="rounded-full d-flex align-items-center justify-content-center">
              <div className="semibold text-white">
                {urlInfo?.urlName.charAt(0) ?? "I"}
              </div>
            </BlackDiv>
          )}
          <div className="semibold ml-3 text-nowrap">
            {urlInfo?.urlName ?? "Integration"}
          </div>
        </div>
        {modelMapping && Object.keys(modelMapping).length > 0 && (
          <div className="text-sm text-gray-50 ml-3 text-nowrap">
            Hover field to see endpoint
          </div>
        )}
      </CardHeader>
      <>
        {modelMapping && Object.keys(modelMapping).length > 0 ? (
          <>
            {Object.keys(modelMapping)
              .sort()
              .map((key, index) => (
                <CardRow
                  key={index}
                  isHover={hoveredIndex === index}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                >
                  {!modelMapping[key] ||
                  !modelMapping[key].api_endpoint_response_traversal_path ? (
                    <div />
                  ) : (
                    <div>
                      {hoveredIndex === index ? (
                        <div className="semibold text-sm">
                          {modelMapping[key].api_endpoint_url}
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <div className="text-md-mono">
                            {
                              constructTraversalPath(
                                modelMapping[key]
                                  .api_endpoint_response_traversal_path
                              ).initial_string
                            }
                          </div>
                          <div className="text-md-mono bold-text">
                            {
                              constructTraversalPath(
                                modelMapping[key]
                                  .api_endpoint_response_traversal_path
                              ).final_value
                            }
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </CardRow>
              ))}
          </>
        ) : status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED ? (
          <EmptyContainer>
            <div className="text-gray-40 h6 rounded-b-lg">
              <div>
                Blueprint could not find any third-party fields for this Common
                Model.{" "}
              </div>{" "}
              <div>
                This is often expected behavior as sometimes the model does not
                exist in the third party. Select a different model on the right
                to see other results.
              </div>
            </div>
          </EmptyContainer>
        ) : (
          <>
            {Array.from({ length: modelRowInput }, (_, index) => (
              <CardRow key={index}>
                <div className="d-flex align-items-center justify-content-start flex-grow-1">
                  <span
                    className="spinner-border-loading-ai text-gray-40"
                    role="status"
                    aria-hidden="true"
                  />
                  <div className="text-sm text-gray-40 ml-2">Generating</div>
                </div>
              </CardRow>
            ))}
          </>
        )}
      </>
    </MappingCard>
  );
};

export default IntegrationMappingCardInput;
