import styled, { css } from "styled-components";
import IntegrationMappingCardInput from "../IntegrationMappingCardInput";
import CommonModelMappingCardInput from "../CommonModelMappingCardInput";
import { CSSTransition } from "react-transition-group";
import ArrowInput from "../ArrowInput";
import { useEffect, useState, useRef, useContext } from "react";
import {
  CheckLLMStatus,
  LLMStatus,
  FavIconResult,
  CategoryModel,
  ModelMapping,
} from "../../models/Entities";
import { ChevronDown } from "lucide-react";
import { Dropdown } from "react-bootstrap";
import Context from "../../context/Context";
import EmailAddressCTA from "./EmailAddressCTA";
import TryAnotherIntegration from "./TryAnotherIntegration";
import SubmitIntegration from "./SubmitIntegration";
import TryItOut from "./TryItOut";
import { compareCategoryAndIntegration } from "../../models/Helpers";
import FilteredFields from "./FilteredFields";
import ScrollRightFade from "../initial_view/ScrollRightFade";
import React from "react";
import { Portal } from "react-portal";
import { usePopper } from "react-popper";

interface Props {
  checkLLMStatus: CheckLLMStatus;
  categoryModels: Record<string, CategoryModel>;
  urlInfo: FavIconResult | null;
  needsReview: () => void;
}

interface InputContainerProps {
  hasEnteredEmail: boolean;
}

const InputContainer = styled.div<InputContainerProps>`
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 56px 1fr;
  gap: 16px;
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none !important;
  padding-bottom: 36px;
  height: ${({ hasEnteredEmail }) =>
    hasEnteredEmail ? "fit-content" : "451px"};

  & > :nth-child(1) {
    grid-row: 1;
    grid-column: 1;
  }

  & > :nth-child(2) {
    grid-row: 1;
    grid-column: 2;
    max-width: 56px;
  }

  & > :nth-child(3) {
    grid-row: 1;
    grid-column: 3;
  }

  & > :nth-child(4) {
    grid-row: 2;
    grid-column: 3;
  }
`;

const DropdownToggle = styled.div`
  cursor: pointer;
  width: auto;
  user-select: none;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
  width: auto;
  align-items: center;
`;

const RotatingChevron = styled(ChevronDown)<{ dropDownOpen: boolean }>`
  transition: transform 0.1s ease-in-out;
  will-change: transform;
  ${(props) =>
    props.dropDownOpen &&
    css`
      transform: rotate(-180deg);
    `};
`;

const ContainerDivScroll = styled.div`
  height: fit-content;
  position: relative;
`;

const ZIndexPortal = styled(Portal)`
  z-index: 2000;
`;

const CommonModelTitle = styled.div`
  margin-left: 8px;
  margin-right: 12px;
`;

const FinalStageComponent = ({
  checkLLMStatus,
  categoryModels,
  urlInfo,
  needsReview,
}: Props) => {
  const { hasEnteredEmail, currentCategory } = useContext(Context);

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Data manipulation   ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  // on flows where they haven't entered their email, we'll want to show them the first non-empty record that appears
  const getFirstNonEmptyRecordKey = (
    records: Record<string, any> | undefined | null
  ) => {
    if (!records) return null;

    const keys = Object.keys(records).sort();
    for (let i = 0; i < keys.length; i++) {
      if (records[keys[i]] && Object.keys(records[keys[i]]).length > 0) {
        return keys[i];
      }
    }
    return null;
  };

  const [commonModelName, setCommonModelName] = useState<string>(
    hasEnteredEmail // if they have already entered their email we can show them any
      ? Object.keys(categoryModels).sort()[0]
      : getFirstNonEmptyRecordKey(checkLLMStatus.mappings) ??
          Object.keys(categoryModels).sort()[0]
  );

  const [integrationMappings, setIntegrationMappings] = useState<
    Record<string, ModelMapping>
  >(checkLLMStatus.mappings);

  useEffect(() => {
    setIntegrationMappings(checkLLMStatus.mappings);
  }, [checkLLMStatus.mappings]);

  const [newIntegrationMappings, setNewIntegrationMappings] = useState<
    Record<string, ModelMapping>
  >({});
  const [newCategoryModels, setNewCategoryModels] = useState<
    Record<string, CategoryModel>
  >({});
  const [filteredOutFields, setFilteredOutFields] = useState<
    Record<string, CategoryModel>
  >({});

  useEffect(() => {
    const result = compareCategoryAndIntegration(
      categoryModels,
      integrationMappings
    );
    setNewIntegrationMappings(result.newIntegrationMappings);
    setNewCategoryModels(result.newCategoryModels);
    setFilteredOutFields(result.filteredOutFields);
  }, [categoryModels, integrationMappings]);

  // we'll want to return the schema of the common models in their natural state if none are mapped
  const schemaMapping =
    categoryModels[commonModelName]?.fields?.sort((a, b) =>
      a.field.localeCompare(b.field)
    ) || [];

  // if some are mapped, we'll want to to filter out the ones that aren't
  const newSchemaMapping =
    (Object.keys(newCategoryModels[commonModelName]?.fields || {}).length === 0
      ? schemaMapping
      : newCategoryModels[commonModelName]?.fields || schemaMapping) || [];

  // filtered out schema
  const filteredSchemaMapping =
    filteredOutFields[commonModelName]?.fields || [];

  const pathName = categoryModels[commonModelName]?.path;

  useEffect(() => {
    if (
      checkLLMStatus.status ===
      LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW
    ) {
      needsReview();
    }
  }, [checkLLMStatus.status, needsReview]);

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Dropdown   ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  /*
   *  All of this below is to handle the case where a dropdown is located inside of a container that needs to scroll horizontally, but not have the dropdown contents contribute to the vertical height
   *  It leverages react-portal, usePopper, and global mouse events to create a dynamic, UX friendly dropdown
   */

  const [dropDownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  const dropdownToggleRef = useRef<HTMLDivElement>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const popperRef = React.useRef<HTMLDivElement | null>(null);

  const [targetElement, setTargetElement] =
    React.useState<HTMLDivElement | null>(null);

  const [popperElement, setPopperElement] =
    React.useState<HTMLDivElement | null>(null);

  const portalRef = document.getElementById("merge-blueprint-main")!;

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: "bottom-start",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  const handleSelect = (selectedKey: string | null) => {
    if (selectedKey) {
      setCommonModelName(selectedKey.trim());
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: globalThis.MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        dropdownMenuRef.current &&
        !dropdownMenuRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
        setPopperElement(null);
      }
    };

    if (dropDownOpen) {
      document.addEventListener("mousedown", handleClickOutside as any);
      setPopperElement(popperRef.current); // set popper element when dropdown opens
    } else {
      document.removeEventListener("mousedown", handleClickOutside as any);
      setPopperElement(null); // set popper element to null when dropdown closes
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside as any);
    };
  }, [dropDownOpen, popperRef]);

  // Listen for window resize events or changes in status to close dropdown
  useEffect(() => {
    const handleResize = () => {
      setDropdownOpen(false);
      setPopperElement(null);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dropDownOpen, checkLLMStatus.status]);

  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// //////////    Mobile Responsiveness   ////////// ////////// ////////// ////////// //////////
  ////////// ////////// ////////// ////////// ////////// ////////// ////////// ////////// //////////

  const [canCheckOverflow, setCanCheckOverflow] = useState<boolean>(false);

  const [overflowXAvailable, setOverflowXAvailable] = useState<boolean>(false);

  useEffect(() => {
    if (
      checkLLMStatus.status ===
        LLMStatus.LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS ||
      checkLLMStatus.status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED
    ) {
      setCanCheckOverflow(true);
    }
  }, [checkLLMStatus.status]);

  useEffect(() => {
    const checkOverflow = () => {
      if (containerRef.current) {
        const overflow =
          containerRef.current.offsetWidth < containerRef.current.scrollWidth;
        setOverflowXAvailable(overflow);
      }
    };
    if (canCheckOverflow) {
      checkOverflow();
    }
    window.addEventListener("resize", checkOverflow);
    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [commonModelName]);

  return (
    <div className="d-flex flex-column w-100 h-100">
      <ContainerDivScroll className="d-flex flex-column align-items-center justify-content-center">
        <InputContainer
          ref={containerRef}
          className="ai-container"
          hasEnteredEmail={hasEnteredEmail}
        >
          <CSSTransition
            in={true}
            timeout={1000}
            classNames="slideFromLeft"
            appear
          >
            <IntegrationMappingCardInput
              urlInfo={urlInfo}
              status={checkLLMStatus.status}
              modelRowInput={newSchemaMapping.length}
              modelMapping={
                newIntegrationMappings
                  ? newIntegrationMappings[commonModelName]
                  : undefined
              }
            />
          </CSSTransition>
          <CSSTransition in={true} timeout={1000} classNames="fade" appear>
            <ArrowInput
              status={checkLLMStatus.status}
              modelMapping={
                newIntegrationMappings
                  ? newIntegrationMappings[commonModelName]
                  : undefined
              }
              modelRowInput={newSchemaMapping.length}
            />
          </CSSTransition>
          <CSSTransition
            in={true}
            timeout={1000}
            classNames="slideFromRight"
            appear
          >
            <CommonModelMappingCardInput
              schemaMapping={newSchemaMapping}
              pathName={pathName}
              category={currentCategory}
              dropdownComponent={
                <>
                  <Dropdown
                    show={dropDownOpen}
                    onSelect={handleSelect}
                    className="dropdown-toggle-no-chevron"
                    ref={dropdownRef}
                  >
                    <DropdownToggle
                      ref={setTargetElement}
                      onClick={() => {
                        if (hasEnteredEmail) {
                          setDropdownOpen(!dropDownOpen);
                        }
                      }}
                    >
                      <div
                        ref={dropdownToggleRef}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <CommonModelTitle className="semibold text-nowrap">
                          {commonModelName} Common Model
                        </CommonModelTitle>
                        <RotatingChevron
                          size={16}
                          dropDownOpen={dropDownOpen}
                        />
                      </div>
                    </DropdownToggle>
                    <ZIndexPortal node={portalRef}>
                      <div
                        ref={popperRef}
                        style={styles.popper}
                        {...attributes.popper}
                      >
                        <Dropdown.Menu
                          ref={dropdownMenuRef}
                          className="dropdown-menu-ai"
                        >
                          {Object.keys(categoryModels)
                            .sort()
                            .map((modelName, index) => (
                              <StyledDropdownItem
                                className="d-flex align-items-center justify-content-between"
                                eventKey={modelName}
                                key={index}
                              >
                                <div>{modelName}</div>
                                <div>
                                  {checkLLMStatus.status !==
                                    LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED && (
                                    <span
                                      className="spinner-border-loading-ai text-gray-40 ml-2"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                  )}
                                </div>
                              </StyledDropdownItem>
                            ))}
                        </Dropdown.Menu>
                      </div>
                    </ZIndexPortal>
                  </Dropdown>
                </>
              }
            />
          </CSSTransition>
          {hasEnteredEmail &&
            newIntegrationMappings[commonModelName] &&
            filteredSchemaMapping.length > 0 && (
              <FilteredFields filteredFieldSchema={filteredSchemaMapping} />
            )}
        </InputContainer>
        <ScrollRightFade
          noLongerRendered={!overflowXAvailable}
          isFinalStage={true}
        />
      </ContainerDivScroll>
      {!hasEnteredEmail && (
        <CSSTransition in={true} timeout={500} classNames="opacity" appear>
          <EmailAddressCTA setIntegrationMappings={setIntegrationMappings} />
        </CSSTransition>
      )}
      <CSSTransition
        in={
          checkLLMStatus.status ===
          LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED
        }
        timeout={500}
        classNames="opacity"
      >
        <div className="d-flex flex-column">
          {hasEnteredEmail &&
            checkLLMStatus.status ===
              LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED &&
            (checkLLMStatus.is_currently_supported_integration ? (
              <CSSTransition
                in={
                  checkLLMStatus.status ===
                  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED
                }
                timeout={500}
                classNames="opacity"
              >
                <TryItOut />
              </CSSTransition>
            ) : (
              <CSSTransition in={true} timeout={500} classNames="opacity">
                <SubmitIntegration />
              </CSSTransition>
            ))}
          {hasEnteredEmail &&
            checkLLMStatus.status ===
              LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED && (
              <CSSTransition
                in={
                  checkLLMStatus.status ===
                  LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED
                }
                timeout={500}
                classNames="opacity"
              >
                <TryAnotherIntegration
                  className="mt-9 mb-3"
                  category={currentCategory}
                />
              </CSSTransition>
            )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default FinalStageComponent;
