const fetch = require("node-fetch");

const APIDomain = "https://api.merge.dev";

export const apiURLForPath = (path: string): string => {
  const baseURL = `${APIDomain}/api`;
  if (path.charAt(0) !== "/") {
    return `${baseURL}/${path}`;
  }
  return baseURL + path;
};

export const fetchWithoutAuthHelper = ({
  url,
  method,
  headers = {},
  body,
  onResponse,
  onError,
}: {
  url: string;
  method: string;
  headers: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const updatedHeaders = {
    ...headers,
    "Content-Type": "application/json;charset=UTF-8",
  };

  fetch(url, {
    method,
    headers: updatedHeaders,
    body: body ? JSON.stringify(body) : null,
  })
    .then((response: any) => {
      if (response.ok) {
        return response.json();
      }
      throw response;
    })
    .then((data: any) => {
      onResponse(data);
    })
    .catch((error: Response | undefined) => {
      if (onError) {
        onError(error && "json" in error ? error : undefined);
      }
    });
};

export const fetchWithoutAuth = ({
  path,
  method = "GET",
  headers = {},
  body,
  onResponse,
  onError,
}: {
  path: string;
  method: string;
  headers?: { [key: string]: string };
  body?: any;
  onResponse: (response: any) => void;
  onError?: (response: Response | undefined) => void;
}) => {
  const url = apiURLForPath(path);
  fetchWithoutAuthHelper({
    url,
    method,
    headers,
    body,
    onResponse,
    onError,
  });
};
