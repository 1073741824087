/*
 * This component is to appease the useTransition mapping hook and to enable a different type of animation
 * from Hayley's incredible designs
 */

const LoadingMappingComponent = () => {
  return <div />;
};

export default LoadingMappingComponent;
