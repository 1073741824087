import styled, { css } from "styled-components";
import PreparingLoadingStage from "./PreparingLoadingStage";
import { CheckLLMStatus, LLMStatus } from "../../models/Entities";
import ReadingComponent from "./ReadingComponent";
import SearchingComponent from "./SearchingComponent";
import LoadingMappingComponent from "./LoadingMappingComponent";
import { useState, useEffect, useContext } from "react";
import {
  TransitionGroup,
  SwitchTransition,
  CSSTransition,
} from "react-transition-group";
import ProcessRequest from "../finished/ProcessRequest";
import Context from "../../context/Context";

interface LoadingStateProps {
  finishedLoadingTransitions: () => void;
  checkLLMStatus: CheckLLMStatus;
}

interface SpinnerLoadingTextProps {
  isShown: boolean;
}

const Container = styled.div`
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingBar = styled.div<{ completed?: boolean }>`
  border-radius: 4px;
  background: ${(props) =>
    props.completed ? "var(--slate90)" : "var(--gray20)"};
  width: 35px;
  transition: background-color 0.5s ease-in-out;
  height: 4px;
`;

const SpinnerLoadingText = styled.div<SpinnerLoadingTextProps>`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  ${({ isShown }) =>
    isShown &&
    css`
      opacity: 1;
    `}
`;

const LoadingStates: React.FC<LoadingStateProps> = ({
  finishedLoadingTransitions,
  checkLLMStatus,
}) => {
  const { hasEnteredEmail } = useContext(Context);

  const status = checkLLMStatus.status;

  const hasEnteredEmailFlow =
    status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS &&
    hasEnteredEmail;

  const hasNotEnteredEmailFlow =
    status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED &&
    !hasEnteredEmail;

  const hasNotEnteredEmailLoading =
    status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS &&
    !hasEnteredEmail;

  const [showSecondDiv, setShowSecondDiv] = useState(false);

  const [renderFirstTransition, setRenderFirstTransition] = useState(false);

  useEffect(() => {
    if (hasEnteredEmailFlow || hasNotEnteredEmailFlow) {
      finishedLoadingTransitions();
    }
  }, [
    status,
    finishedLoadingTransitions,
    hasNotEnteredEmailLoading,
    showSecondDiv,
  ]);

  const handlePreparingComplete = () => {
    setShowSecondDiv(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderFirstTransition(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  let ComponentToRender = null;
  let componentKey = "";
  let stateText = "";

  if (
    status === LLMStatus.LLM_MAPPING_EXECUTION_STATUS_IN_QUEUE ||
    (!renderFirstTransition &&
      status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS &&
      status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW)
  ) {
    ComponentToRender = SearchingComponent;
    componentKey = "SearchingComponent";
    stateText = "Finding API Documentation";
  } else {
    switch (status) {
      case LLMStatus.LLM_MAPPING_EXECUTION_STATUS_PULLING_API_DOCS:
        ComponentToRender = ReadingComponent;
        componentKey = "ReadingComponent";
        stateText = "Reading API Documentation";
        break;
      case LLMStatus.LLM_MAPPING_EXECUTION_STATUS_PARSING_API_DOCS:
        ComponentToRender = (
          <PreparingLoadingStage
            onPreparingComplete={handlePreparingComplete}
          />
        );
        componentKey = "PreparingComponent";
        stateText = "Preparing Information";
        break;
      case LLMStatus.LLM_MAPPING_EXECUTION_STATUS_MAPPING_MODELS:
        if (hasEnteredEmail) {
          ComponentToRender = LoadingMappingComponent;
          componentKey = "LoadingMappingComponent";
        } else if (!hasEnteredEmail) {
          ComponentToRender = (
            <PreparingLoadingStage
              onPreparingComplete={handlePreparingComplete}
            />
          );
          componentKey = "PreparingComponent";
          stateText = "Preparing Information";
        }
        break;
      case LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW:
        ComponentToRender = ProcessRequest;
        componentKey = "ProcessRequest";
        break;
      default:
        ComponentToRender = null;
        componentKey = "";
    }
  }

  return (
    <CSSTransition in={true} timeout={500} classNames="opacity" appear>
      <Container>
        <div className="h-100 w-100 d-flex flex-column">
          <TransitionGroup component={null}>
            {ComponentToRender && (
              <SwitchTransition>
                <CSSTransition
                  key={componentKey}
                  unmountOnExit
                  mountOnEnter
                  classNames={{
                    enter: "fade-enter",
                    enterActive: "fade-enter-active",
                    exit: "fade-exit",
                    exitActive: "fade-exit-active",
                  }}
                  timeout={500}
                >
                  {typeof ComponentToRender === "function" ? (
                    <ComponentToRender />
                  ) : (
                    ComponentToRender
                  )}
                </CSSTransition>
              </SwitchTransition>
            )}
          </TransitionGroup>
          {status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_APPROVED &&
            status !== LLMStatus.LLM_MAPPING_EXECUTION_STATUS_NEEDS_REVIEW && (
              <>
                {showSecondDiv ? (
                  <SpinnerLoadingText
                    isShown={showSecondDiv}
                    className="d-flex align-items-center justify-content-center mt-9 mb-8"
                  >
                    <span
                      className="text-gray-50 spinner-border-ai"
                      role="status"
                      aria-hidden="true"
                    />
                    <h3 className="text-gray-50 ml-2">Loading Data Models</h3>
                  </SpinnerLoadingText>
                ) : (
                  <>
                    {stateText && (
                      <h3 className="d-flex mt-9 mb-6 align-items-center justify-content-center">
                        {stateText}
                      </h3>
                    )}
                    <div className="d-flex align-items-center justify-content-center">
                      <LoadingBar
                        className="mr-2"
                        completed={
                          status ===
                            LLMStatus.LLM_MAPPING_EXECUTION_STATUS_IN_QUEUE ||
                          !renderFirstTransition
                        }
                      />
                      <LoadingBar
                        className="mr-2"
                        completed={
                          status ===
                            LLMStatus.LLM_MAPPING_EXECUTION_STATUS_PULLING_API_DOCS &&
                          renderFirstTransition
                        }
                      />
                      <LoadingBar
                        completed={
                          (status ===
                            LLMStatus.LLM_MAPPING_EXECUTION_STATUS_PARSING_API_DOCS &&
                            renderFirstTransition) ||
                          hasNotEnteredEmailLoading
                        }
                      />
                    </div>
                  </>
                )}
              </>
            )}
        </div>
      </Container>
    </CSSTransition>
  );
};

export default LoadingStates;
