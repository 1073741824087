import { MergeFormButton } from "@merge-api/merge-javascript-shared";
import { useContext } from "react";
import styled from "styled-components";
import Context from "../../context/Context";

interface FadeOut {
  fadeOut: boolean;
}

const TryItOutContainer = styled.div<FadeOut>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background: #ffffff;
  padding: 24px;
  position: relative;
  opacity: ${(props) => (props.fadeOut ? "0" : "1")};
  transition: 1s ease-out;
`;

const openAppInNewTab = () => {
  window.open("https://app.merge.dev/", "_blank");
};

const TryItOut = () => {
  const { kickOffRun } = useContext(Context);

  return (
    <TryItOutContainer fadeOut={kickOffRun} className="shadow-md rounded-lg">
      <div className="d-flex flex-column align-content-start">
        <h4 className="mb-2">
          Great news! Merge already supports this integration!
        </h4>
        <div className="text-lg">
          Start using Merge for free to access this integration.
        </div>
      </div>
      <MergeFormButton
        className="align-self-center"
        color="black"
        size="medium"
        buttonIcon="chevronIcon"
        onClick={openAppInNewTab}
      >
        Try it out
      </MergeFormButton>
    </TryItOutContainer>
  );
};

export default TryItOut;
