import React from "react";

export interface ContextState {
  /*
   * Serves our transitions and various states
   */

  hasEnteredEmail: boolean;
  setHasEnteredEmail: (hasEnteredEmail: boolean) => void;
  emailEntered: string;
  setEmailEntered: (value: string) => void;
  expandedBackground: boolean;
  setExpandedBackground: (expandedBackground: boolean) => void;
  hasSeenURLReqModal: boolean;
  setHasSeenURLReqModal: (expandedBackground: boolean) => void;

  /*
   * For kicking off the flow again from different areas of the app
   */

  currentCategory: string;
  setCurrentCategory: (value: string) => void;
  currentURLForRun: string;
  setCurrentURLForRun: (value: string) => void;
  kickOffRun: boolean;
  setKickOffRun: (kickOffRun: boolean) => void;
  currentExecutionID: string;
  setCurrentExecutionID: (value: string) => void;
}

const Context = React.createContext<ContextState>({
  hasEnteredEmail: false,
  setHasEnteredEmail: () => {},
  emailEntered: "",
  setEmailEntered: () => {},
  expandedBackground: false,
  setExpandedBackground: () => {},
  hasSeenURLReqModal: false,
  setHasSeenURLReqModal: () => {},

  currentCategory: "",
  setCurrentCategory: () => {},
  currentURLForRun: "",
  setCurrentURLForRun: () => {},
  kickOffRun: false,
  setKickOffRun: () => {},
  currentExecutionID: "",
  setCurrentExecutionID: () => {},
});

export default Context;
