import readingData from "../../assets/animations/reading.json";
import Lottie from "react-lottie";

const ReadingComponent = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: readingData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={200}
      width={213}
      isClickToPauseDisabled={true}
    />
  );
};

export default ReadingComponent;
