import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";

interface Props {
  isRendered: boolean;
}

const loading = keyframes`
  from {
    transform: translateX(-150%);
  }
  to {
    transform: translateX(400%);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LoadingBar = styled.div<Props>`
  background: var(--gray20);
  width: 25px;
  height: 2px;
  position: relative;
  overflow: hidden;
  animation: ${({ isRendered }) => (isRendered ? fadeOut : "none")} 1s ease-out;
`;

const LoadingProgress = styled.div<{ delay: number }>`
  background: black;
  width: 25%;
  height: 100%;
  position: absolute;
  animation: ${loading} 2s linear infinite;
  will-change: transform;
  animation-delay: ${({ delay }) => `${delay}s`};
`;

const getRandomDelay = (max: number) => Math.random() * max;

const BabyLoadingIcon = ({ isRendered }: Props) => {
  const [delay, setDelay] = useState(0);

  useEffect(() => {
    setDelay(getRandomDelay(1));
  }, []);
  return (
    <LoadingContainer>
      <LoadingBar isRendered={isRendered}>
        <LoadingProgress delay={delay} />
      </LoadingBar>
    </LoadingContainer>
  );
};

export default BabyLoadingIcon;
